import React, { useEffect, useState } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DataTable from "components/Custom/DataTable";
import { HTTP, imageFromURL, uploadFile, uploadVideo, Request } from "helpers/api_helper";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { badger } from "components/Custom/XBadge";
import moment from "moment";

const dataCols = [
  {
    dataField: "student",
    text: "Öğrenci",
    type: "text",
  },
  {
    dataField: "studentExamResult",
    text: "Öğrenci Sınav Sonucu",
    type: "text",
    readonly: true,
  },
  {
    dataField: "date",
    text: "Tarih",
    type: "text",
    readonly: true,
  },
  {
    dataField: "number",
    text: "Telefon Numarası",
    type: "text",
    readonly: true,
  },
  {
    dataField: "sent",
    text: "Gönderildi",
    type: "text",
    readonly: true,
  },
];

const SentMessages = (props) => {
  document.title = props.title || "Gönderilen Mesajlar";
  useEffect(() => {
    props.onSetBreadCrumbs("Gönderilen Mesajlar", [
      { title: "Anasayfa", url: "/" },
      { title: "Gönderilen Mesajlar", url: "/gonderilen-mesajlar" },
    ]);
  }, []);

  const [tableData, setTableData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [activeViewItem, setActiveViewItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useMultiModalItem, setMultiModalItem] = useState({});

  const toggleViewModal = (item) => {
    setViewModal(!viewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };

  const toggleMultiModal = () => {
    setMultiModal(!useMultiModal);
  }

  useEffect(() => {
    fetchSentMessages();
  }, [currentPage, pageSize]);

  const fetchSentMessages = () => {
    Request.get(`/sentMessage?page=${currentPage}&pageSize=${pageSize}`)
      .then((response) => {
        const { data, objectCount, pageCount } = response.data;
        setTableData(data.map(mapData));
        setTotalItems(objectCount);
        setPageCount(pageCount);
      })
      .catch((error) => {
        console.error("Error fetching sent messages:", error);
      });
  };

  const mapData = (item, index) => ({
    num: (currentPage - 1) * pageSize + index + 1,
    student: `${item.studentExamResult.student.firstName} ${item.studentExamResult.student.lastName}`,
    studentExamResult: (
      <button className="btn btn-success" onClick={() => toggleViewModal(item)}>
        {`${item.studentExamResult.student.firstName} ${item.studentExamResult.student.lastName} Sınav Sonucu`}
      </button>
    ),
    date: moment(item.studentExamResult.date).format("DD.MM.YYYY"),
    number: item.number,
    sent: item.sent ? <Badge color="success">Gönderildi</Badge> : <Badge color="danger">Gönderilmedi</Badge>,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleMultiModalChange = (e) => {
    if (e.target.type === "file") {
      setMultiModalItem({
        ...useMultiModalItem,
        [e.target.name]: e.target.files,
      });
    } else {
      setMultiModalItem({
        ...useMultiModalItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleMultiModalSubmit = () => {
    console.log("useMultiModalItem:", useMultiModalItem.multiFile[0]);
    if (useMultiModalItem && useMultiModalItem.multiFile) {
      const file = useMultiModalItem.multiFile[0];
      const formData = new FormData();
      formData.append("file", file);
      Request.post("/import/examResultWithFile", formData).then((response) => {
        fetchSentMessages();
      }).then(() => {
        setMultiModalItem({});
        setMultiModal(false);
      }).catch((error) => {
        console.error("Error importing exam results with file:", error);
      });
    }
  };

  const paginationBasic = (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div>
        <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} style={{ marginRight: '5px' }}>
          <i className="mdi mdi-chevron-left"></i> Önceki
        </Button>
        {Array.from({ length: pageCount }, (_, index) => (
          <Button key={index} onClick={() => handlePageChange(index + 1)} active={currentPage === index + 1} style={{ marginRight: '5px' }}>
            {index + 1}
          </Button>
        ))}
        <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage * pageSize >= totalItems} style={{ marginRight: '5px' }}>
          Sonraki <i className="mdi mdi-chevron-right"></i>
        </Button>
      </div>
    </div>
  );

  return (
    <Container fluid className="p-0">
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <Col>
              <CardTitle className="h4">Gönderilen Mesajlar</CardTitle>
              <Button color="primary" style={{marginLeft: "1rem"}} onClick={toggleMultiModal}>
                Toplu Sonuç Ekle
              </Button>
            </Col>
            <Col className="text-right">
              <div className="form-group" style={{display:'flex', justifyContent:'end'}}>
                <Label for="pageSize" style={{marginRight:'5px', verticalAlign:'middle'}}>Sayfa Boyutu:</Label>
                <div className="col-6 col-md-2 pull-right">
                  <Input type="select" id="pageSize" value={pageSize} onChange={handlePageSizeChange} className="form-control-sm">
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Input>
                </div>
              </div>
            </Col>
          </Row>
          <div className="table-responsive mt-3">
            <BootstrapTable
              keyField="num"
              data={tableData}
              columns={dataCols}
              striped
              hover
              condensed
            />
          </div>
          <div className="mt-3">
            {paginationBasic}
          </div>
          <XModal
            title="Görüntüle"
            itemTitleValue={`${activeViewItem?.studentExamResult?.student.firstName} ${activeViewItem?.studentExamResult?.student.lastName}`}
            showModal={viewModal}
            toggle={toggleViewModal}
            saveButton={false}
          >
            <div className="form-group row mb-2">
              <Label for="student" className="col-sm-3 col-form-label">Öğrenci</Label>
              <div className="col-sm-9">
                <Input
                  id="student"
                  className="form-control"
                  type="text"
                  value={`${activeViewItem?.studentExamResult?.student.firstName} ${activeViewItem?.studentExamResult?.student.lastName}`}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row mb-2">
              <Label for="date" className="col-sm-3 col-form-label">Tarih</Label>
              <div className="col-sm-9">
                <Input
                  id="date"
                  className="form-control"
                  type="text"
                  value={moment(activeViewItem?.studentExamResult?.date).format("DD.MM.YYYY")}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row mb-2">
              <Label for="number" className="col-sm-3 col-form-label">Telefon Numarası</Label>
              <div className="col-sm-9">
                <Input
                  id="number"
                  className="form-control"
                  type="text"
                  value={activeViewItem?.number}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row mb-2">
              <Label for="sent" className="col-sm-3 col-form-label">Gönderildi</Label>
              <div className="col-sm-9">
                <Input
                  id="sent"
                  className="form-control"
                  type="text"
                  value={activeViewItem?.sent ? "Evet" : "Hayır"}
                  readOnly
                />
              </div>
            </div>
          </XModal>
          <XModal
            title="Toplu Sonuç Ekle"
            showModal={useMultiModal}
            toggle={toggleMultiModal}
            onSubmit={handleMultiModalSubmit}
          >
            <div className="form-group row mb-2">
              <Label for="multiFile" className="col-sm-3 col-form-label">Dosya Seç</Label>
              <div className="col-sm-9">
                <Input
                  id="multiFile"
                  className="form-control"
                  type="file"
                  name="multiFile"
                  onChange={handleMultiModalChange}
                />
              </div>
            </div>
          </XModal>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, items) => dispatch(setBreadcrumbItems(title, items)),
});

export default connect(null, mapDispatchToProps)(SentMessages);
