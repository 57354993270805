import React from 'react';

const X = (props) => {
  const { xIf, xFor } = props;
  const xIfDefined = xIf !== undefined;
  const xForDefined = xFor !== undefined;

  const xForElements = [];
  if (xForDefined) {
    for (let i = 0; i < xFor.length; i++) {
      xForElements.push(xFor[i]);
    }
  }

  return (
    <>
      {xIfDefined && xIf && !xForDefined && props.children}
      {xIfDefined && xIf && xForDefined && xForElements.map((item, index) => {
        return React.cloneElement(props.children(item, index), { key: index });
      })}
      {!xIfDefined && xForDefined && xForElements.map((item, index) => {
        return React.cloneElement(props.children(item, index), { key: index });
      })}
    </>
  );
};

export default X;


// Usage sample:
// <X xIf={true} xFor={someArray}>
//  {item => (
//    <>{item}<br /></>
//  )}
// </X>