import React from "react"
import { Badge } from "reactstrap"

const XBadge = props => {
  const { color, pill, className, children } = props
  return (
    <span
      className={`badge badge-${color} ${pill ? "badge-pill" : ""} ${className}`}
    >
      {children}
    </span>
  )
}

export const badger = (texts = ["success"], color = "primary") => {
  return texts.map((text, index) => {
    return (
      <Badge key={index} color={color} style={{ marginRight: "5px" }}>
        {text}
      </Badge>
    )
  })
}
