import React from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  const user = JSON.parse(localStorage.getItem("authUser"));
  const role = user.role;
  return (
  <React.Fragment>
    {props.roles.includes(role) ? props.children : <Navigate to="/login" />}
  </React.Fragment>);
};

export default Authmiddleware;
