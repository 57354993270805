import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import { token } from "./helpers";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
// const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3030";
export const API_URL = process.env.REACT_APP_API_URL || "https://api.sedusskids.com";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + token || accessToken;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => throwError(error)
);

export const apiURL = (url='') => (API_URL + url);
export const fileURL = (id, width=0, height=0) => {
  let url = API_URL + '/file/fileLink/' + id;
  if(width > 0 && height > 0) url += '?width=' + width + '&height=' + height;
  else if(width > 0) url += '?width=' + width;
  else if(height > 0) url += '?height=' + height;
  return url;
}
export const audioURL = (id) => (API_URL + '/file/fileLink/audio/' + id);

export const imageFromURL = (id, sizes={}, style={}, alt="", onClick=()=>{}) => {
  let width = "auto";
  let height = "auto";
  let fileURLString = '';
  if(sizes.width && sizes.width > 0 && sizes.height && sizes.height > 0) {
    width = sizes.width + "px";
    height = sizes.height + "px";
    fileURLString = fileURL(id, width, height);
  } else if(sizes.width && sizes.width > 0) {
    width = sizes.width + "px";
    fileURLString = fileURL(id, width);
  } else if(sizes.height && sizes.height > 0) {
    height = sizes.height + "px";
    fileURLString = fileURL(id, 0, height);
  } else {
    fileURLString = fileURL(id);
  }
  return <img src={fileURLString} style={{ width: width, height: height, ...style }} alt={alt} onClick={()=>onClick()} />;
};

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

const throwError = err  => {
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Üzgünüm! Aradığınız sayfa bulunumadı.";
          break;
        case 500:
          message =
            "Üzgünüm! Bir şeyler ters gitti, lütfen destek ekibimizle iletişime geçin.";
          break;
        case 401:
        case 406:
          message = "Kimlik bilgileri geçersizdir.";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
}

class RequestClass {
  constructor() {
    this.config = {};
    this.authHeaders = {
      Authorization: `Bearer ${token || accessToken}`,
    };
  }

  get = async (url, headers={}) => {
    return await axiosApi
      .get(url, { ...headers });
  }

  post = async (url, data, headers={}) => {
    return await axiosApi
      .post(url, { ...data }, { ...headers });
  }

  put = async (url, data, headers={}) => {
    return await axiosApi
      .put(url, { ...data }, { ...headers });
  }

  patch = async (url, data, headers={}) => {
    return await axiosApi
      .patch(url, { ...data }, { ...headers });
  }

  del = async (url, headers={}) => {
    return await axiosApi
      .delete(url, { ...headers });
  }

  setConfig = (config) => {
    this.config = config;
  }

  getConfig = () => {
    return this.config;
  }
}

export const Request = new RequestClass();
export const HTTP = new RequestClass();

export async function uploadFile(file, config = {}) {
  const formData = new FormData();
  formData.append("file", file);
  return await axiosApi
    .post("/file", formData, { ...config })
    .then((response) => response.data);
}

export async function uploadFileToMP3(file, config = {}) {
  const formData = new FormData();
  formData.append("file", file);
  return await axiosApi
    .post("/file/toMP3", formData, { ...config })
    .then((response) => response.data);
}

export async function uploadVideo(file, videoTitle="", config = {}) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("videoTitle", videoTitle);

  if(config?.toMP3 && config?.toMP3 === true) {
    formData.append('toMP3', true);
  }

  return await axiosApi
    .post("/file/video", formData, { ...config })
    .then((response) => response.data);
}

export async function text2Speech(text, config = {}) {
  let language = 'en';
  if(config.language) {
    language = config.language;
  }
  let response = await axiosApi.post(`/text2Speech/${language}`, { text }, { ...config })
  response = await response.data;
  const id = response?.voiceFile?._id;
  return {
    url: audioURL(id),
    id: id,
  };
}

export async function text2SpeechTR(text, config = {}) {
  let response = await axiosApi.post("/text2Speech/tr", { text }, { ...config })
  response = await response.data;
  const id = response?.voiceFile?._id;
  return {
    url: audioURL(id),
    id: id,
  };
}