import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DataTable from "components/Custom/DataTable";
import { HTTP, imageFromURL, uploadFile, uploadVideo } from "helpers/api_helper";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Progress, Row, Spinner, Tooltip } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { filter } from "lodash";

const dataCols = [
  {
    dataField: "title",
    text: "Başlık",
    type: "text",
    filter: textFilter({
      placeholder: "Başlık Filtrele..."
    }),
  },
  {
    dataField: "slug",
    text: "Slug",
    type: "text",
    readonly: true,
    showOnTable: false,
  },
  {
    dataField: "lesson",
    text: "Ders",
    type: "select",
    field: "name",
    filter: textFilter({
      placeholder: "Ders Filtrele..."
    }),
  }, 
  {
    dataField: "topics",
    text: "Konular",
    type: "multiselect",
    field: "name",
    filter: textFilter({
      placeholder: "Konu Filtrele..."
    }),
  }, 
  {
    dataField: "coverImage",
    text: "Kapak Resmi",
    type: "image",
  },
  {
    dataField: "video",
    text: "Video",
    type: "video",
    showOnTable: false,
  },
  {
    dataField: "lectureQuestions",
    text: "Konu Anlatımı Soruları",
    type: "dynamicInputs",
  },
];
const formInput = {
  marginTop: "0.5rem",
};
const visibleDataCols = dataCols.filter((dataCol) => {
  return dataCol.showOnTable !== false;
});
const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...visibleDataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];
const url = "lecture";
const pageTitle = "Tüm Ders Anlatımları";
const modelPluralName = "Ders Anlatımları";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Ders Anlatımları", url: "/ders-anlatimlari" },
];
const badges = ["primary", "secondary", "success", "info", "warning", "danger", "dark"];

const LecturesPage = props => {
  document.title = props.title || "Ders Anlatımları";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useVideoIdNew, setVideoIdNew] = useState("");
  const [useVideoIdEdit, setVideoIdEdit] = useState("");
  const [useVideoUploadProgressNew, setVideoUploadProgressNew] = useState(0);
  const [useVideoUploadProgressEdit, setVideoUploadProgressEdit] = useState(0);

  const [useDataCols, setDataCols] = useState(dataCols);
  const [useCols, setCols] = useState(cols);
  const [useLessons, setLessons] = useState([]);
  const [useCurrentLesson, setCurrentLesson] = useState({});
  const [useVideoProcessingSpinnerNew, setVideoProcessingSpinnerNew] = useState(true);
  const [useVideoProcessingSpinnerEdit, setVideoProcessingSpinnerEdit] = useState(true);

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
    // Check if response 200
    if(item?.video?.remotes[0]?.uploaded === true) {
      setVideoProcessingSpinnerNew(false);
    } else {
      setVideoProcessingSpinnerNew(true);
    }
  };
  const toggleEditModal = (item) => {
    if(item) {
      setActiveEditItem(item);
      setActiveEditItemName(item.name);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setNewModal(!useNewModal);
  }

  const handleEditItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveNewItem({
        ...useActiveNewItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveNewItem({
        ...useActiveNewItem,
        [e.target.name]: e.target.value,
      });
    }

    if(e.target.name === "lesson") {
      setCurrentLesson(useLessons.find((lesson) => lesson._id === e.target.value));
    }
  };

  const handleEdit = (activeItem) => {
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        const file = activeItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          activeItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.type === "video" && activeItem[dataCol.dataField]) {
        const file = activeItem[dataCol.dataField].files[0];
        const videoTitle = activeItem["title"];
        fileUploadPromises.push(uploadVideo(file, videoTitle).then((response) => {
          const fileId = response._id;
          activeItem[dataCol.dataField] = fileId;
        }));
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      HTTP.put(`/${url}/${activeItem?._id}`, activeItem).then(response => {
        HTTP.get(`/${url}`).then(response => {
          setDataTableData(response.data);
          return response.data;
        }).then(data => {
          const fetchedTableData = fetchTableData(data);
          setTableData(fetchedTableData);
          return fetchedTableData;
        }).then(() => {
          toggleEditModal();
        });
      });
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    console.log(useActiveNewItem);
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const imageFile = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(imageFile).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.name === "parentTopic" && useActiveNewItem[dataCol.dataCol.name] === "") {
        delete useActiveNewItem[dataCol.dataField];
      } else if(dataCol.type === "video" && useActiveNewItem[dataCol.dataField]) {
        const videoFile = useActiveNewItem[dataCol.dataField].files[0];
        const videoTitle = useActiveNewItem["title"];
        fileUploadPromises
          .push(uploadVideo(videoFile, videoTitle)
          .then((response) => {
            const fileId = response._id;
            useActiveNewItem[dataCol.dataField] = fileId;
            return fileId;
          })
          .then(fileId => {
            setVideoIdNew(fileId);
            return fileId;
          }));
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      HTTP.post(`/${url}`, useActiveNewItem).then(response => {
        HTTP.get(`/${url}`).then(response => {
          setDataTableData(response.data);
          return response.data;
        }).then(data => {
          const fetchedTableData = fetchTableData(data);
          setTableData(fetchedTableData);
          return fetchedTableData;
        }).then(() => {
          toggleNewModal();
          setActiveNewItem({});
        });
      });
    });
  };

  const handleDelete = (activeItem) => {
    HTTP.del(`/${url}/${activeItem._id}`).then(response => {
      HTTP.get(`/${url}`).then(response => {
        setDataTableData(response.data);
        return response.data;
      }).then(data => {
        const fetchedTableData = fetchTableData(data);
        setTableData(fetchedTableData);
        return fetchedTableData;
      }).then(() => {
        toggleDeleteModal();
      });
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    return data.map((item, index) => {
      return {
        num: index + 1,
        title: item.title,
        slug: item.slug,
        lesson: item?.lesson?.name || "-",
        topics: item?.topics?.map((topic) => topic.name).join(", "),
        coverImage: imageFromURL(item?.coverImage?._id, {width: 50}, item.title),
        // video: item?.video || "-",
        actions: 
          <ActionsCol 
            id={item._id} 
            onView={()=>{toggleViewModal(item)}}
            onEdit={()=>{toggleEditModal(item)}}
            onDelete={()=>{toggleDeleteModal(item)}}
          />,
      }
    });
  };

  useEffect(() => {
    HTTP.get(`/${url}`).then(response => {
      setDataTableData(response.data);
      return response.data
    }).then(data => {
      const fetchedTableData = fetchTableData(data);
      setTableData(fetchedTableData);
      return fetchedTableData;
    }); 
  }, []);
  //! End Core Codes

  
  useEffect(() => {
    HTTP.get(`/lesson`).then(response => {
      setLessons(response.data);
      return response.data;
    }).then(data => {
      setSelectedLessonNew(data[0]);
      return data[0];
    }).then(data => {
      handleNewItemChange({target: {name: "lesson", value: data._id}});
      HTTP.get(`/topic/byLesson/${data._id}`).then(response => {
        const topics = response.data;
        setParentTopicsNew(formatParentTopicsNew(topics));
      });
    });
  }, []);


  //:y New
  const formatParentTopicsNew = (parentTopics=[]) => {
    return [
      { _id: "", name: "-", selected: true },
      ...parentTopics
    ]
  };
  const [useSelectedLessonNew, setSelectedLessonNew] = useState([]);
  const [useParentTopicsNew, setParentTopicsNew] = useState(formatParentTopicsNew());
  const [useLectureQuestionInputs, setLectureQuestionInputs] = useState([]);
  const [useLectureQuestions, setLectureQuestions] = useState([]);
  const [useAddLectureQuestionTooltip, setAddLectureQuestionTooltip] = useState(false);
  const toggleAddLectureQuestionTooltip = () => setAddLectureQuestionTooltip(!useAddLectureQuestionTooltip);
  useEffect(() => {
    if(useCurrentLesson?._id) {
      HTTP.get(`/topic/byLesson/${useCurrentLesson._id}`).then(response => {
        const topics = response.data;
        setParentTopicsNew(formatParentTopicsNew(topics));
      });
    }
  }, [useCurrentLesson]);
  useEffect(() => {
    if(useVideoIdNew && useVideoIdNew !== "") {
      var videoUploadProgressNewInterval;
      if(useVideoUploadProgressNew != 100) {
        videoUploadProgressNewInterval = setInterval(() => {
          HTTP.get(`/file/remoteProgress/${useVideoIdNew}`).then(response => {
            setVideoUploadProgressNew(response.data.progress);
          });
        }, 2000);
      } else {
        clearInterval(videoUploadProgressNewInterval);
      }
    }
  }, [useVideoIdNew]);

  const handleAddQuestionInput = () => {
    const newLectureQuestionInputs = [...useLectureQuestionInputs];
    newLectureQuestionInputs.push({
      question: "",
      answers: ["",""],
      correctAnswer: -1,
    });
    setLectureQuestionInputs(newLectureQuestionInputs);
  };

  const handleEditQuestionInput = (questionIndex, e) => {
    const newLectureQuestionInputs = [...useLectureQuestionInputs];
    newLectureQuestionInputs[questionIndex].question = e.target.value;
    setLectureQuestionInputs(newLectureQuestionInputs);
  };

  const handleEditQuestionAnswerInput = (questionIndex, answerIndex, e) => {
    const newLectureQuestionInputs = [...useLectureQuestionInputs];
    newLectureQuestionInputs[questionIndex].answers[answerIndex] = e.target.value;
    setLectureQuestionInputs(newLectureQuestionInputs);
  };

  useEffect(() => {
    setActiveNewItem({
      ...useActiveNewItem,
      lectureQuestions: useLectureQuestionInputs,
    });
  }, [useLectureQuestionInputs]);

  //:y Edit
  const formatParentTopicsEdit = (parentTopics=[]) => {
    return [
      { _id: "", name: "-", selected: true },
      ...parentTopics
    ]
  }
  const [useSelectedLessonEdit, setSelectedLessonEdit] = useState([]);
  const [useParentTopicsEdit, setParentTopicsEdit] = useState(formatParentTopicsEdit());
  
  useEffect(() => {
    if(useActiveEditItem?.lesson?._id) {
      HTTP.get(`/topic/byLesson/${useActiveEditItem.lesson._id}`).then(response => {
        const topics = response.data;
        setParentTopicsEdit(formatParentTopicsEdit(topics));
      });
    }
  }, [useActiveEditItem]);
  
  
  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                      Yeni Ekle
                    </Button>
                  </CardTitle>
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
        size="xl"
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="title"
                className="form-control"
                type="text"
                value={useActiveNewItem["title"] ? useActiveNewItem["title"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="lesson" className="col-sm-3 col-form-label">Ders</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="lesson"
                className="form-control"
                type="select"
                onChange={handleNewItemChange}
                value={useActiveNewItem["lesson"]?._id}
              >
                {/* {useLessons.map((option) => {
                  return (
                    <option value={option._id}>{option.name}</option>
                  )
                })} */}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="topics" className="col-sm-3 col-form-label">Konular</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="topics"
                className="form-control"
                type="select"
                multiple={true}
                onChange={handleNewItemChange}
                value={useActiveNewItem["topics"]?._id}
              >
                {useParentTopicsNew.map((option) => {
                  return (
                    <option value={option._id}>{option.name}</option>
                  )
                })}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="coverImage" className="col-sm-3 col-form-label">Kapak Resmi</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="coverImage"
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="video" className="col-sm-3 col-form-label">Video</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="video"
                className="form-control"
                type="file"
                accept="video/*"
                onChange={handleNewItemChange}
              />
              <Progress value={useVideoUploadProgressNew} max="100" style={{marginTop: ".5rem"}}>
                {useVideoUploadProgressNew}%
              </Progress>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="lectureQuestions" className="col-sm-3 col-form-label">Konu Anlatımı Soruları</Label>
            <div className="col-12" style={formInput}>
              <div className="row">
                <div className="col-12">
                  Toplam Soru: <b>{useLectureQuestionInputs.length}</b>
                  <Button id={"addLectureQuestion"} className="btn btn-sm btn-success" style={{marginLeft: "1rem"}} title="Soru Ekle" onClick={handleAddQuestionInput}>
                    <i className="mdi mdi-plus"></i>
                  </Button>
                  <Tooltip 
                    placement="top" 
                    isOpen={useAddLectureQuestionTooltip} 
                    target={"addLectureQuestion"}
                    toggle={toggleAddLectureQuestionTooltip}
                  >
                    Soru Ekle
                  </Tooltip>
                </div>
                <div className="col-12">
                  {useLectureQuestionInputs.map((lectureQuestionInput, lectureQuestionInputsIndex) => {
                    return (
                      <div className="lectureQuestion">
                        <div className="form-group">
                          <Label for="question" className="col-sm-3 col-form-label">
                            Soru {lectureQuestionInputsIndex+1}
                            <Button 
                              className="btn btn-sm btn-danger"
                              style={{marginLeft: "1rem"}}
                              title="Soruyu Sil"
                              onClick={() => {
                                const newLectureQuestionInputs = [...useLectureQuestionInputs];
                                newLectureQuestionInputs.splice(lectureQuestionInputsIndex, 1);
                                setLectureQuestionInputs(newLectureQuestionInputs);
                              }}
                            >
                              <i className="mdi mdi-delete"></i>
                            </Button>
                          </Label>
                          <div className="col-12" style={formInput}>
                            <Input
                              name="question"
                              className="form-control"
                              type="text"
                              value={lectureQuestionInput?.question}
                              onChange={e => handleEditQuestionInput(lectureQuestionInputsIndex, e)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <Label for="answers" className="col-sm-3 col-form-label">Seçenekler</Label>
                          <div className="col-12" style={formInput}>
                            {lectureQuestionInput?.answers.map((answer, answerIndex) => {
                              return (
                                <div className="row mb-2">
                                  <div className="col-10 col-lg-11">
                                    <Input
                                      name="answers"
                                      className="form-control"
                                      type="text"
                                      value={answer}
                                      onChange={(e) => {
                                        handleEditQuestionAnswerInput(lectureQuestionInputsIndex, answerIndex, e);
                                      }}
                                    />
                                  </div>
                                  <div className="col-2 col-lg-1">
                                    <Button 
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        const newLectureQuestionInputs = [...useLectureQuestionInputs];
                                        newLectureQuestionInputs[lectureQuestionInputsIndex].answers.splice(answerIndex, 1);
                                        setLectureQuestionInputs(newLectureQuestionInputs);
                                      }}
                                      title="Cevabı Sil"
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </Button>
                                  </div>
                                </div>
                              )
                            })}
                            <Button className="btn btn-sm btn-success" 
                              onClick={() => {
                                const newLectureQuestionInputs = [...useLectureQuestionInputs];
                                newLectureQuestionInputs[lectureQuestionInputsIndex].answers.push("");
                                setLectureQuestionInputs(newLectureQuestionInputs);
                              }}
                              title="Cevap Ekle"
                            >
                              <i className="mdi mdi-plus"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="form-group">
                          <Label for="correctAnswer" className="col-sm-3 col-form-label">Doğru Cevap</Label>
                          <div className="col-12" style={formInput}>
                            <Input
                              name="correctAnswer"
                              className="form-control"
                              type="select"
                              value={lectureQuestionInput?.correctAnswer}
                              onChange={(e) => {
                                const newLectureQuestionInputs = [...useLectureQuestionInputs];
                                newLectureQuestionInputs[lectureQuestionInputsIndex].correctAnswer = parseInt(e.target.value);
                                setLectureQuestionInputs(newLectureQuestionInputs);
                              }}
                            >
                              <option value={-1}>Seçiniz</option>
                              {lectureQuestionInput?.answers.map((answer, answerIndex) => {
                                return (
                                  <option value={answerIndex}>{answer}</option>
                                )
                              })}
                            </Input>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                        
                </div>
              </div>
            </div>
          </div>
          
        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false} size="xl">
        <div className="form-group row mb-2">
          <Label for="name" className="col-sm-3 col-form-label">Başlık</Label>
          <div className="col-sm-9" style={formInput}>
            <Input
              name="title"
              className="form-control"
              type="text"
              value={useActiveViewItem["title"]}
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="slug" className="col-sm-3 col-form-label">Slug</Label>
          <div className="col-sm-9" style={formInput}>
            <Input
              name="slug"
              className="form-control"
              type="text"
              value={useActiveViewItem["slug"]}
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="lesson" className="col-sm-3 col-form-label">Ders</Label>
          <div className="col-sm-9" style={formInput}>
            <Input
              name="lesson"
              className="form-control"
              type="text"
              value={useActiveViewItem["lesson"]?.name}
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="topics" className="col-sm-3 col-form-label">Konular</Label>
          <div className="col-sm-9" style={formInput}>
            {useActiveViewItem["topics"]?.map((topic,index) => {
              return (
                <h5>
                  <Badge color={badges[index % badges.length]} className="mr-1">{topic.name}</Badge>
                </h5>
              )
            })}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="coverImage" className="col-sm-3 col-form-label">Kapak Resmi</Label>
          <div className="col-sm-9" style={formInput}>
            {imageFromURL(useActiveViewItem["coverImage"]?._id, {width: 200}, useActiveViewItem["name"])}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="video" className="col-sm-3 col-form-label">Video</Label>
          <div className="col-sm-9" style={formInput}>
            {useVideoProcessingSpinnerNew ? (
              <Spinner color="success">
                Video Yükleniyor...
              </Spinner>
            ): (
              <iframe src={"https://iframe.mediadelivery.net/embed/232175/" + useActiveViewItem?.video?.remotes[0]?.videoId} frameborder="0"
                style={{width: "100%", height: "20rem"}} allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="lectureQuestions" className="col-sm-3 col-form-label">Konu Anlatımı Soruları</Label>
          <div className="col-12" style={formInput}>
            {useActiveViewItem?.lectureQuestions?.map((lectureQuestion, lectureQuestionIndex) => {
              return (
                <div className="lectureQuestion">
                  <div className="form-group">
                    <Label for="question" className="col-sm-3 col-form-label">
                      Soru {lectureQuestionIndex+1}
                    </Label>
                    <div className="col-12" style={formInput}>
                      {lectureQuestion?.question}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label for="answers" className="col-sm-3 col-form-label">Seçenekler</Label>
                    <div className="col-12" style={formInput}>
                      {lectureQuestion?.answers.map((answer, answerIndex) => {
                        return (
                          <div className="row mb-2">
                            <div className="col-10 col-lg-11">
                              {answer}
                            </div>
                            <div className="col-2 col-lg-1">
                              {lectureQuestion?.correctAnswer === answerIndex ? (
                                <Badge color="success">Doğru Cevap</Badge>
                              ) : (
                                <Badge color="danger">Yanlış Cevap</Badge>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="title"
                className="form-control"
                type="text"
                value={useActiveEditItem["title"] ? useActiveEditItem["title"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="lesson" className="col-sm-3 col-form-label">Ders</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="lesson"
                className="form-control"
                type="select"
                onChange={handleEditItemChange}
                value={useActiveEditItem["lesson"]?._id}
              >
                {/* {useLessons.map((option) => {
                  return (
                    <option value={option._id}>{option.name}</option>
                  )
                })} */}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="topics" className="col-sm-3 col-form-label">Konular</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="topics"
                className="form-control"
                type="select"
                multiple={true}
                onChange={handleEditItemChange}
                value={useActiveEditItem["topics"]?._id}
              >
                {useParentTopicsNew.map((option) => {
                  return (
                    <option value={option._id}>{option.name}</option>
                  )
                })}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="coverImage" className="col-sm-3 col-form-label">Kapak Resmi</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="coverImage"
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="video" className="col-sm-3 col-form-label">Video</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="video"
                className="form-control"
                type="file"
                accept="video/*"
                onChange={handleEditItemChange}
              />
              <Progress value={useVideoUploadProgressNew} max="100" style={{marginTop: ".5rem"}}>
                {useVideoUploadProgressNew}%
              </Progress>
            </div>
          </div>
          
        </form>
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.name} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {useActiveDeleteItem?.name}'i silmek istediğinize emin misiniz?
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LecturesPage);
