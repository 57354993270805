import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";

const PageHeader = (props) => {
  const {pageTitle, objectCount, objectName, cols, tableData, cellEdit, toggleNewModal} = props;

  return (
    <div className="page-content p-0">
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4">
                  {pageTitle}
                  <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                    Yeni Ekle
                  </Button>
                </CardTitle>
                Toplam {objectCount} {objectName} bulunmaktadır.
                <div className="table-responsive">
                  <BootstrapTable
                    keyField="num"
                    data={tableData}
                    columns={cols}
                    filter={filterFactory()}
                    filterPosition="inline"
                    cellEdit={ cellEdit }
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageHeader;