import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { imageFromURL, uploadFile, Request, text2Speech, audioURL } from "helpers/api_helper";
import { Button, Col, Input, Label, Row, } from "reactstrap";
import XModal from "components/Custom/XModal";
import ActionsCol from "components/Custom/ActionsCol";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import X from "components/Custom/X";
import PageHeader from "pages/components/PageHeader";
import { ColorInput, FileInputInRow, ImageInput, InlineCheckboxInput, NumberInput, SelectInput, SelectInputColIndex, Text2SpeechInput, TextareaInputInRow, TextInputInRow } from "pages/components/Inputs";
import { isIn } from "helpers/helpers";

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  {
    dataField: "lesson",
    text: "Ders",
    type: "text",
    editable: false,
    filter: selectFilter({
      options: [],
    }),
  },
  {
    dataField: "topic",
    text: "Konu",
    type: "text",
    editable: false,
    filter: selectFilter({
      options: [],
    }),
  },
  {
    dataField: "chapter",
    text: "Bölüm",
    type: "text",
    editable: false,
    filter: selectFilter({
      options: [],
    }),
  },
  {
    dataField: "order",
    text: "Sıra",
    type: "number",
    editor: {
      type: Type.TEXT,
    },
  },
  {
    dataField: "questionType",
    text: "Soru Tipi",
    type: "text",
    filter: textFilter(),
  },
  {
    dataField: "question",
    text: "Soru",
    type: "text",
    editable: false,
  },
  {
    dataField: "point",
    text: "Puan",
    type: "number",
  },
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
    editable: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const url = "question";
const pageTitle = "Sorular";
const modelPluralName = "Sorular";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Sorular", url: "/sorular" },
];

const questionTypes = {
  multipleChoicesString: "Çoktan Seçmeli (Metin)",
  multipleChoicesFile: "Çoktan Seçmeli (Dosya)",
  multipleChoicesStringWithFile: "Çoktan Seçmeli (Metin ve Dosya)",
  voiceoverWriting: "Sesli Mesajı Anlama ve Yazma",
  userVoiceover: "Kullanıcının Seslendirmesi",
  formingSentences: "Cümle Kurma",

  painting: "Boyama",
  zeppeline: "Zeplin",
  letter: "Harf ile Sürükleme",
  objectWithStartingLetter: "Nesne ve Harf Eşleştirme",
  numberBasket: "Sepete Obje Atma",
  drawingLetter: "Harf Çizme",
};

const Chapters = props => {
  document.title = props.title || "Sorular";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useChapters, setChapters] = useState([]);
  const [useAllResponse, setAllResponse] = useState({});
  const [useLessons, setLessons] = useState([]);
  const [useTopicsNew, setTopicsNew] = useState([]);
  const [useChaptersNew, setChaptersNew] = useState([]);
  const [useSelectedLessonNew, setSelectedLessonNew] = useState("");
  const [useSelectedTopicNew, setSelectedTopicNew] = useState("");
  const [useSelectedChapterNew, setSelectedChapterNew] = useState("");
  const [useSelectedQuestionTypeNew, setSelectedQuestionTypeNew] = useState("multipleChoicesString");
  const [useHasPictureNew, setHasPictureNew] = useState(false);
  const [usePictureNew, setPictureNew] = useState("");
  const [usePictureNewFileId, setPictureNewFileId] = useState("");
  const [useHasVoiceNew, setHasVoiceNew] = useState(false);
  const [useVoiceTextNew, setVoiceTextNew] = useState("");
  const [useVoiceURL, setVoiceURL] = useState("");
  const [useVoiceId, setVoiceId] = useState("");
  const [useHasTextNew, setHasTextNew] = useState(false);
  const [useTextNew, setTextNew] = useState("");
  const [useOptions, setOptions] = useState([]);
  const [useOptionsOptions, setOptionsOptions] = useState([]);
  const [useOptionsVoiceTexts, setOptionsVoiceTexts] = useState([]);
  const [useOptionsVoiceURLs, setOptionsVoiceURLs] = useState([]);
  const [useOptionsVoiceIds, setOptionsVoiceIds] = useState([]);
  const [useOptionsTexts, setOptionsTexts] = useState([]);
  const [useOptionsPictures, setOptionsPictures] = useState([]);
  const [useOptionsPictureIds, setOptionsPictureIds] = useState([]); 
  const [useQuestionPoint, setQuestionPoint] = useState(0);
  const [useCorrectAnswerHasIndex, setCorrectAnswerHasIndex] = useState(true);
  const [useCorrectAnswerHasText, setCorrectAnswerHasText] = useState(false);
  const [useCorrectAnswerHasFile, setCorrectAnswerHasFile] = useState(false);
  const [useCorrectAnswerIndex, setCorrectAnswerIndex] = useState(0);
  const [useCorrectAnswerText, setCorrectAnswerText] = useState("");
  const [useCorrectAnswerTexts, setCorrectAnswerTexts] = useState(['']);
  const [useCorrectAnswerFile, setCorrectAnswerFile] = useState("");
  const [useCorrectAnswerFileId, setCorrectAnswerFileId] = useState("");
  const [useIsFormingSentencesQuestionNew, setIsFormingSentencesQuestionNew] = useState(false);
  const [useIsFormingSentencesQuestionNewCorrectAnswers, setIsFormingSentencesQuestionNewCorrectAnswers] = useState([]);
  const [useCorrectAnswerHasVoiceNew, setCorrectAnswerHasVoiceNew] = useState(false);
  const [useCorrectAnswerVoiceTextNew, setCorrectAnswerVoiceTextNew] = useState("");
  const [useCorrectAnswerVoiceURLNew, setCorrectAnswerVoiceURLNew] = useState("");
  const [useCorrectAnswerVoiceIdNew, setCorrectAnswerVoiceIdNew] = useState("");
  const [useSetLanguageNew, setSetLanguageNew] = useState('');
  //:y Edit
  const [useEditModal, setEditModal] = useState(false);
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useTopicsEdit, setTopicsEdit] = useState([]);
  const [useChaptersEdit, setChaptersEdit] = useState([]);
  const [useSelectedLessonEdit, setSelectedLessonEdit] = useState("");
  const [useSelectedTopicEdit, setSelectedTopicEdit] = useState("");
  const [useSelectedChapterEdit, setSelectedChapterEdit] = useState("");
  const [useSelectedQuestionTypeEdit, setSelectedQuestionTypeEdit] = useState("");
  const [useHasPictureEdit, setHasPictureEdit] = useState(false);
  const [usePictureEdit, setPictureEdit] = useState("");
  const [usePictureEditFileId, setPictureEditFileId] = useState("");
  const [useHasVoiceEdit, setHasVoiceEdit] = useState(false);
  const [useVoiceTextEdit, setVoiceTextEdit] = useState("");
  const [useVoiceEditURL, setVoiceEditURL] = useState("");
  const [useVoiceIdEdit, setVoiceIdEdit] = useState("");
  const [useHasTextEdit, setHasTextEdit] = useState(false);
  const [useTextEdit, setTextEdit] = useState("");
  const [useOptionsEdit, setOptionsEdit] = useState([]);
  const [useOptionsOptionsEdit, setOptionsOptionsEdit] = useState([]);
  const [useOptionsVoiceTextsEdit, setOptionsVoiceTextsEdit] = useState([]);
  const [useOptionsVoiceURLsEdit, setOptionsVoiceURLsEdit] = useState([]);
  const [useOptionsVoiceIdsEdit, setOptionsVoiceIdsEdit] = useState([]);
  const [useOptionsTextsEdit, setOptionsTextsEdit] = useState([]);
  const [useOptionsPicturesEdit, setOptionsPicturesEdit] = useState([]);
  const [useOptionsPictureIdsEdit, setOptionsPictureIdsEdit] = useState([]);
  const [useQuestionPointEdit, setQuestionPointEdit] = useState(0);
  const [useCorrectAnswerHasIndexEdit, setCorrectAnswerHasIndexEdit] = useState(false);
  const [useCorrectAnswerHasTextEdit, setCorrectAnswerHasTextEdit] = useState(false);
  const [useCorrectAnswerHasFileEdit, setCorrectAnswerHasFileEdit] = useState(false);
  const [useCorrectAnswerIndexEdit, setCorrectAnswerIndexEdit] = useState(0);
  const [useCorrectAnswerTextEdit, setCorrectAnswerTextEdit] = useState("");
  const [useCorrectAnswerTextsEdit, setCorrectAnswerTextsEdit] = useState(['']);
  const [useCorrectAnswerFileEdit, setCorrectAnswerFileEdit] = useState("");
  const [useCorrectAnswerFileIdEdit, setCorrectAnswerFileIdEdit] = useState("");
  const [useIsFormingSentencesQuestionEdit, setIsFormingSentencesQuestionEdit] = useState(false);
  const [useIsFormingSentencesQuestionEditCorrectAnswers, setIsFormingSentencesQuestionEditCorrectAnswers] = useState([]);
  const [useCorrectAnswerHasVoiceEdit, setCorrectAnswerHasVoiceEdit] = useState(false);
  const [useCorrectAnswerVoiceTextEdit, setCorrectAnswerVoiceTextEdit] = useState("");
  const [useCorrectAnswerVoiceURLEdit, setCorrectAnswerVoiceURLEdit] = useState("");
  const [useCorrectAnswerVoiceIdEdit, setCorrectAnswerVoiceIdEdit] = useState("");
  const [useSetLanguageEdit, setSetLanguageEdit] = useState('');
  //:y /Edit

  //:y Exercises
  const [useIsPaintBucket, setIsPaintBucket] = useState(false);
  const [useColorOptions, setColorOptions] = useState([]);
  const [useIsPaintBucketEdit, setIsPaintBucketEdit] = useState(false);
  const [useColorOptionsEdit, setColorOptionsEdit] = useState([]);

  //:y /Exercises

  const getAbbr = (lessons, id) => {
    const lesson = lessons.find(lesson => lesson._id === id);
    return lesson?.abbr;
  }

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    if(item) {
      setActiveViewItem(item);
    }  else {
      setActiveViewItem({});
    }
  };
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setMultiModal(!useMultiModal);
  }

  const toggleEditModal = (item) => {
    if(item) {
      setActiveEditItem(item);
      console.log("EDIT ITEM", item);
    } else {
      setActiveEditItem({});
    }
    if(useEditModal) {
      setEditModal(false);
    } else {
      setEditModal(true);
    }
  }

  const handleHeaderLesson = (data) => {
    const dataMapsForLesson = data.map(lesson => { return {value: lesson.name, label:lesson.name} });
    const updatedCols = [...useCols];
    const lessonColIndex = updatedCols.findIndex(col => col.dataField === "lesson");
    if (lessonColIndex !== -1) {
      updatedCols[lessonColIndex].formatter = (cell, row) => {
        const selectedLesson = data.find(lesson => lesson.name === cell);
        if(selectedLesson) {
          setSelectedLessonNew(selectedLesson?._id);
        }
        return cell;
      };
      updatedCols[lessonColIndex].filter = selectFilter({
        options: dataMapsForLesson,
      });
      setCols(updatedCols);
    }
  };

  const handleHeaderTopic = (data) => {
    if(data && data.length > 0) {
      const dataMapsForTopic = data.map(topic => { return {value: topic.name, label:topic.name} });
      const updatedCols = [...useCols];
      const topicColIndex = updatedCols.findIndex(col => col.dataField === "topic");
      if (topicColIndex !== -1) {
        updatedCols[topicColIndex].formatter = (cell, row) => {
          const selectedTopic = data.find(topic => topic.name === cell);
          if(selectedTopic) {
            setSelectedTopicNew(selectedTopic?._id);
          }
          return cell;
        };
        updatedCols[topicColIndex].filter = selectFilter({
          options: dataMapsForTopic,
        });
        setTimeout(() => {
          setCols(updatedCols);
        }, 500);
      }
    }
  };

  const handleHeaderChapter = (data) => {
    if(data && data.length > 0) {
      const dataMapsForChapter = data.map(chapter => { return {value: chapter.name, label:chapter.name} });
      const updatedCols = [...useCols];
      const chapterColIndex = updatedCols.findIndex(col => col.dataField === "chapter");
      if (chapterColIndex !== -1) {
        updatedCols[chapterColIndex].filter = selectFilter({
          options: dataMapsForChapter,
        });
        setTimeout(() => {
          setCols(updatedCols);
        }, 500);
      }
    }
  };

  useEffect(() => {
    console.log("activeEditItem", useActiveEditItem);
    setSelectedLessonEdit(useActiveEditItem?.lesson?._id);
    setSetLanguageEdit(useActiveEditItem?.lesson?.language?.abbr);
    setSelectedTopicEdit(useActiveEditItem?.topics?._id);
    setTimeout(() => {
      setSelectedChapterEdit(useActiveEditItem?.chapter?._id);
    },[1000]);
    setSelectedQuestionTypeEdit(useActiveEditItem?.questionType);
    setQuestionPointEdit(useActiveEditItem?.point);
    if(useActiveEditItem?.question?.imageFiles && useActiveEditItem?.question?.imageFiles.length > 0) {
      setHasPictureEdit(true);
      setPictureEdit(useActiveEditItem?.question?.imageFiles[0]);
      setPictureEditFileId(useActiveEditItem?.question?.imageFiles[0]);
    }
    if(useActiveEditItem?.question?.voiceFiles && useActiveEditItem?.question?.voiceFiles.length > 0) {
      setHasVoiceEdit(true);
      // setVoiceTextEdit(useActiveEditItem?.question?.voiceFiles[0]);
      setVoiceEditURL(audioURL(useActiveEditItem?.question?.voiceFiles[0]));
      setVoiceIdEdit(useActiveEditItem?.question?.voiceFiles[0]);
    }
    if(useActiveEditItem?.question?.texts && useActiveEditItem?.question?.texts.length > 0) {
      setHasTextEdit(true);
      setTextEdit(useActiveEditItem?.question?.texts[0]);
    }
    if(useActiveEditItem?.options && useActiveEditItem?.options.length > 0) {
      setOptionsEdit(useActiveEditItem?.options);
      const optionsOptions = useActiveEditItem?.options.map(option => {
        return {
          hasPicture: option?.valueImageFile ? true : false,
          hasVoice: option?.valueVoiceFile ? true : false,
          hasText: option?.value ? true : false,
        };
      });
      setOptionsOptionsEdit(optionsOptions);
      const optionsVoiceTexts = useActiveEditItem?.options.map(option => {
        return "";
      });
      setOptionsVoiceTextsEdit(optionsVoiceTexts);
      const optionsVoiceURLs = useActiveEditItem?.options.map(option => {
        return audioURL(option?.valueVoiceFile);
      });
      setOptionsVoiceURLsEdit(optionsVoiceURLs);
      const optionsVoiceIds = useActiveEditItem?.options.map(option => {
        return "";
      });
      setOptionsVoiceIdsEdit(optionsVoiceIds);
      const optionsTexts = useActiveEditItem?.options.map(option => {
        return option?.value;
      });
      setOptionsTextsEdit(optionsTexts);
      const optionsPictures = useActiveEditItem?.options.map(option => {
        return option?.valueImageFile;
      });
      setOptionsPicturesEdit(optionsPictures);
      const optionsPictureIds = useActiveEditItem?.options.map(option => {
        return option?.valueImageFile;
      });
      setOptionsPictureIdsEdit(optionsPictureIds);
    }
    if(useActiveEditItem?.correctAnswer?.index) {
      setCorrectAnswerHasIndexEdit(true);
      setCorrectAnswerIndexEdit(useActiveEditItem?.correctAnswer?.index);
    }
    if(useActiveEditItem?.correctAnswer?.text || useActiveEditItem?.correctAnswer?.texts) {
      setCorrectAnswerHasTextEdit(true);
      // setCorrectAnswerTextEdit(useActiveEditItem?.correctAnswer?.text);
      if(useActiveEditItem?.correctAnswer?.texts) {
        setCorrectAnswerTextsEdit(useActiveEditItem?.correctAnswer?.texts);
      } else if(useActiveEditItem?.correctAnswer?.text) {
        setCorrectAnswerTextsEdit([useActiveEditItem?.correctAnswer?.text]);
      }
    }
    if(useActiveEditItem?.correctAnswer?.file) {
      setCorrectAnswerHasFileEdit(true);
      setCorrectAnswerFileEdit(useActiveEditItem?.correctAnswer?.file);
      setCorrectAnswerFileIdEdit(useActiveEditItem?.correctAnswer?.file);
    }
    if(useActiveEditItem?.correctAnswer?.voiceFile) {
      setCorrectAnswerHasVoiceEdit(true);
      setCorrectAnswerVoiceTextEdit(useActiveEditItem?.correctAnswer?.voiceFile);
      setCorrectAnswerVoiceIdEdit(useActiveEditItem?.correctAnswer?.voiceFile);
      setCorrectAnswerVoiceURLEdit(audioURL(useActiveEditItem?.correctAnswer?.voiceFile));
    }
    if(useActiveEditItem?.correctAnswer?.customValue?.answer) {
      setIsFormingSentencesQuestionEdit(true);
      setIsFormingSentencesQuestionEditCorrectAnswers(useActiveEditItem?.correctAnswer?.customValue?.answer);
    }

    if(useActiveEditItem?.colors) {
      setColorOptionsEdit(useActiveEditItem?.colors);
    }


  }, [useActiveEditItem]);

  const onMoveUp = (item) => {
    if(item.order > 1) {
      Request.post(`/${url}/move/${item._id}/${item.order - 1}`, {}).then(response => {
        fetchEntire();
      });
    }
  };

  const onMoveDown = (item) => {
    Request.post(`/${url}/move/${item._id}/${item.order + 1}`, {}).then(response => {
      fetchEntire();
    });
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };

  const handleNew = () => {
    const question = {};
    if(useHasPictureNew) {
      question.imageFiles = [usePictureNewFileId];
    }
    if(useHasVoiceNew) {
      question.voiceFiles = [useVoiceId];
    }
    if(useHasTextNew) {
      question.texts = [useTextNew];
    }

    const options = [];
    for(let i = 0; i < useOptions.length; i++) {
      const option = {};
      if(useOptionsOptions[i]?.hasPicture) {
        option.valueImageFile = useOptionsPictureIds[i];
      }
      if(useOptionsOptions[i]?.hasVoice) {
        option.valueVoiceFile = useOptionsVoiceIds[i];
      }
      if(useOptionsOptions[i]?.hasText) {
        option.value = useOptionsTexts[i];
      }
      options.push(option);
    }

    const correctAnswer = {};
    if(useCorrectAnswerHasIndex) {
      correctAnswer.index = parseInt(useCorrectAnswerIndex);
    }
    if(useCorrectAnswerHasText) {
      const texts = useCorrectAnswerTexts.filter(text => text !== "");
      correctAnswer.texts = texts;
    }
    if(useCorrectAnswerHasFile) {
      correctAnswer.file = useCorrectAnswerFileId;
    }
    if(useCorrectAnswerHasVoiceNew) {
      correctAnswer.voiceFile = useCorrectAnswerVoiceIdNew;
    }

    const data = {
      lesson: useSelectedLessonNew,
      topics: useSelectedTopicNew,
      chapter: useSelectedChapterNew,
      point: useQuestionPoint,
      questionType: useSelectedQuestionTypeNew,
      question,
      options,
      correctAnswer,
    };

    if(useSelectedQuestionTypeNew === "painting") {
      data.colors = useColorOptions;
      data.isPaintBucket = useIsPaintBucket;
    }

    if(useIsFormingSentencesQuestionNew) {
      data.correctAnswer = {
        customValue: {
          answer: useIsFormingSentencesQuestionNewCorrectAnswers,
        },
      };
      if(!data.question.texts && !data.question.voiceFiles && !data.question.imageFiles) {
        data.question.texts = [""];
      }
    }

    (async () => {
      console.log("data", data);
      const response = await Request.post(`/${url}`, data);
      toggleNewModal();
      await fetchEntire();
      resetAll();
    })();
  };

  const handleEdit = () => {
    const question = {};
    if(useHasPictureEdit) {
      question.imageFiles = [usePictureEditFileId];
    }
    if(useHasVoiceEdit) {
      question.voiceFiles = [useVoiceIdEdit];
    }
    if(useHasTextEdit) {
      question.texts = [useTextEdit];
    }

    const options = [];
    for(let i = 0; i < useOptionsEdit.length; i++) {
      const option = {};
      if(useOptionsOptionsEdit[i]?.hasPicture) {
        option.valueImageFile = useOptionsPictureIdsEdit[i];
      }
      if(useOptionsOptionsEdit[i]?.hasVoice) {
        option.valueVoiceFile = useOptionsVoiceIdsEdit[i];
      }
      if(useOptionsOptionsEdit[i]?.hasText) {
        option.value = useOptionsTextsEdit[i];
      }
      options.push(option);
    }

    const correctAnswer = {};
    if(useCorrectAnswerHasIndexEdit) {
      correctAnswer.index = parseInt(useCorrectAnswerIndexEdit);
    }
    if(useCorrectAnswerHasTextEdit) {
      // clear "" from texts
      const texts = useCorrectAnswerTextsEdit.filter(text => text !== "");
      correctAnswer.texts = texts;
    }
    if(useCorrectAnswerHasFileEdit) {
      correctAnswer.file = useCorrectAnswerFileIdEdit;
    }
    if(useCorrectAnswerHasVoiceEdit && useCorrectAnswerVoiceIdEdit) {
      correctAnswer.voiceFile = useCorrectAnswerVoiceIdEdit;
    }

    const data = {
      lesson: useSelectedLessonEdit,
      topics: useSelectedTopicEdit,
      chapter: useSelectedChapterEdit,
      point: useQuestionPointEdit,
      questionType: useSelectedQuestionTypeEdit,
      question,
      options,
      correctAnswer,
    };

    if(useSelectedQuestionTypeEdit === "painting") {
      data.colors = useColorOptionsEdit;
      data.isPaintBucket = useIsPaintBucketEdit;
    }

    if(useIsFormingSentencesQuestionEdit) {
      data.correctAnswer = {
        customValue: {
          answer: useIsFormingSentencesQuestionEditCorrectAnswers,
        },
      };
      if(!data.question.texts && !data.question.voiceFiles && !data.question.imageFiles) {
        data.question.texts = [""];
      }
    }

    (async () => {
      console.log("UPDATE DATA", data);
      const response = await Request.put(`/${url}/${useActiveEditItem._id}`, data);
      toggleEditModal();
      await fetchEntire();
      resetAll();
    })();
  };

  const resetAll = async () => {
    setActiveNewItem({});
    setSelectedLessonNew("");
    setSelectedTopicNew("");
    setSelectedChapterNew("");
    setSelectedQuestionTypeNew("multipleChoicesString");
    setHasPictureNew(false);
    setPictureNew("");
    setPictureNewFileId("");
    setHasVoiceNew(false);
    setVoiceId("");
    setVoiceURL("");
    setVoiceTextNew("");
    setHasTextNew(false);
    setTextNew("");
    setOptions([{},{}]);
    setOptionsOptions([
      {hasPicture: false, hasVoice: false, hasText: false}, 
      {hasPicture: false, hasVoice: false, hasText: false},
    ]);
    setOptionsVoiceTexts([]);
    setOptionsVoiceURLs([]);
    setOptionsVoiceIds([]);
    setOptionsTexts([]);
    setOptionsPictures([]);
    setOptionsPictureIds([]);
    setQuestionPoint(0);
    setCorrectAnswerHasIndex(true);
    setCorrectAnswerHasText(false);
    setCorrectAnswerHasFile(false);
    setCorrectAnswerIndex(0);
    setCorrectAnswerText("");
    setCorrectAnswerFile("");
    setCorrectAnswerFileId("");
    setIsFormingSentencesQuestionNew(false);
    setIsFormingSentencesQuestionNewCorrectAnswers([]);
    setCorrectAnswerHasVoiceNew(false);
    setCorrectAnswerVoiceTextNew("");
    setCorrectAnswerVoiceURLNew("");
    setCorrectAnswerVoiceIdNew("");

    setActiveEditItem({});
    setSelectedLessonEdit("");
    setSelectedTopicEdit("");
    setSelectedChapterEdit("");
    setSelectedQuestionTypeEdit("");
    setHasPictureEdit(false);
    setPictureEdit("");
    setPictureEditFileId("");
    setHasVoiceEdit(false);
    setVoiceIdEdit("");
    setVoiceEditURL("");
    setVoiceTextEdit("");
    setHasTextEdit(false);
    setTextEdit("");
    setOptionsEdit([]);
    setOptionsOptionsEdit([]);
    setOptionsVoiceTextsEdit([]);
    setOptionsVoiceURLsEdit([]);
    setOptionsVoiceIdsEdit([]);
    setOptionsTextsEdit([]);
    setOptionsPicturesEdit([]);
    setOptionsPictureIdsEdit([]);
    setQuestionPointEdit(0);
    setCorrectAnswerHasIndexEdit(false);
    setCorrectAnswerHasTextEdit(false);
    setCorrectAnswerHasFileEdit(false);
    setCorrectAnswerIndexEdit(0);
    setCorrectAnswerTextEdit("");
    setCorrectAnswerFileEdit("");
    setCorrectAnswerFileIdEdit("");
    setIsFormingSentencesQuestionEdit(false);
    setIsFormingSentencesQuestionEditCorrectAnswers([]);
    setSetLanguageEdit('');
    setSetLanguageNew('');

    await fetchEntire();
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`).then(response => {
      Request.get(`/${url}`).then(response => {
        return response.data;
      }).then(data => {
        fetchEntire();
      }).then(() => {
        toggleDeleteModal();
      });
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    data = data;
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        let res ={
          num: index + 1,
          lesson: item.lesson?.name,
          topic: item.topics?.name,
          chapter: item.chapter?.name,
          order: item.order,
          questionType: item.questionType,
          point: item.point,
          _id: item._id,
          
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={()=>{toggleViewModal(item); }}
              onDelete={()=>{toggleDeleteModal(item)}}
              onEdit={()=>{toggleEditModal(item)}}
              onMoveUp={()=>{onMoveUp(item)}}
              onMoveDown={()=>{onMoveDown(item)}}
            />,
        };
        if(item?.question?.imageFiles && item.question?.imageFiles.length > 0) {
          res.question = imageFromURL(item.question?.imageFiles[0], {width: 50, height: 50}, {height:"50px", width:"auto"} );
        } else if (item?.question?.texts && item.question?.texts.length > 0) {
          res.question = item.question?.texts[0].substring(0, 100);
        } else if (item?.question?.voiceFiles && item.question?.voiceFiles.length > 0) {
          res.question = <audio controls src={audioURL(item.question?.voiceFiles[0])} style={{height: "40px"}} />;
        }
        return res;
      });
      return mapped;
    }
    return [];
  };
  const fetchChapters = () => {
    Request.get("/chapter").then(response => {
      setChapters(response.data);
    });
  };

  const sortData = data => {
    data.sort((a, b) => {
      return a.order - b.order;
    });
    return data;
  };

  const fetchEntire = async () => {
    let response = await Request.get(`/${url}`);
    let data = response.data;
    data = sortData(data);

    setDataTableData(data);
    setAllResponse(data);

    const fetchedTableData = fetchTableData(data);
    setTableData(fetchedTableData);

    fetchChapters();

    const lessonResponse = await Request.get(`/lesson`);
    const lessonData = lessonResponse.data;
    setLessons(lessonData);

    const activeLesson = lessonData[0]?._id;
    setActiveNewItem({...useActiveNewItem, lesson: activeLesson});
    setSelectedLessonNew(activeLesson);
    setSetLanguageNew(lessonData[0]?.abbr);

    const topicResponse = await Request.get(`/topic/byLesson/${activeLesson}`);
    const topicData = topicResponse.data;
    setTopicsNew(topicData);

    const activeTopic = topicData[0]?._id;
    setActiveNewItem({...useActiveNewItem, topic: activeTopic});
    setSelectedTopicNew(activeTopic);

    const chapterResponse = await Request.get(`/chapter/byTopic/${activeTopic}`);
    const chapterData = chapterResponse.data;
    setChaptersNew(chapterData);
    const activeChapter = chapterData[0]?._id;
    setSelectedChapterNew(activeChapter);

    handleHeaderLesson(lessonData);

  }

  useEffect(() => {
    fetchEntire();
    setOptions([{}, {}]);
    setOptionsOptions([
      {hasPicture: false, hasVoice: false, hasText: false}, 
      {hasPicture: false, hasVoice: false, hasText: false},
    ]);
    setOptionsEdit([{}, {}]);
    setOptionsOptionsEdit([
      {hasPicture: false, hasVoice: false, hasText: false}, 
      {hasPicture: false, hasVoice: false, hasText: false},
    ]);
  }, []);


  useEffect(() => {
    if(useSelectedLessonNew && useSelectedLessonNew != '') {
      Request.get(`/topic/byLesson/${useSelectedLessonNew}`)
      .then(response => {
        setTopicsNew(response.data);
        setSelectedTopicNew(response.data[0]?._id);
        return response.data;
      }).then((data) => {
        handleHeaderTopic(data); 
        return data;
      }).then(data => {
        Request.get(`/chapter/byTopic/${data[0]?._id}`)
        .then(response => {
          setChaptersNew(response.data);
          setSelectedChapterNew(response.data[0]?._id);
          return response.data;
        }).then(data => {
          handleHeaderChapter(data);
        });
      });
    }
  }, [useSelectedLessonNew]);

  useEffect(() => {
    if(useSelectedLessonEdit && useSelectedLessonEdit != '') {
      Request.get(`/topic/byLesson/${useSelectedLessonEdit}`)
      .then(response => {
        setTopicsEdit(response.data);
        setSelectedTopicEdit(response.data[0]?._id);
        Request.get(`/chapter/byTopic/${response.data[0]?._id}`)
        .then(response => {
          setChaptersEdit(response.data);
          setSelectedChapterEdit(response.data[0]?._id);
        });
      });
    }
  }, [useSelectedLessonEdit]);


  useEffect(() => {
    if(usePictureNew.type) {
      const file = usePictureNew.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setPictureNewFileId(response._id);
        });
      }
    } else {
      setPictureNewFileId("");
    }
  }, [usePictureNew]);

  useEffect(() => {
    if(usePictureEdit.type) {
      const file = usePictureEdit.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setPictureEditFileId(response._id);
        });
      }
    } else {
      // setPictureEditFileId("");
    }
  }, [usePictureEdit]);


  useEffect(() => {
    if(useCorrectAnswerFile.type) {
      const file = useCorrectAnswerFile.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setCorrectAnswerFileId(response._id);
        });
      }
    }
  }, [useCorrectAnswerFile]);

  useEffect(() => {
    if(useCorrectAnswerFileEdit.type) {
      const file = useCorrectAnswerFileEdit.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setCorrectAnswerFileIdEdit(response._id);
        });
      }
    }
  }, [useCorrectAnswerFileEdit]);


  useEffect(() => {
    if(useSelectedQuestionTypeNew === "painting") {
      setHasPictureNew(true);
    }
    if(useSelectedQuestionTypeNew == "formingSentences") {
      setIsFormingSentencesQuestionNew(true);
      const optionsCount = useOptions.length;
      const correctAnswers = [];
      for(let i = 0; i < optionsCount; i++) {
        correctAnswers.push(i);
      }
      setIsFormingSentencesQuestionNewCorrectAnswers(correctAnswers);
    } else {
      setIsFormingSentencesQuestionNew(false);
    }
  }, [useSelectedQuestionTypeNew, useOptions]);

  useEffect(() => {
    if(useSelectedQuestionTypeEdit == "formingSentences") {
      setIsFormingSentencesQuestionEdit(true);
      const optionsCount = useOptionsEdit.length;
      const correctAnswers = [];
      for(let i = 0; i < optionsCount; i++) {
        correctAnswers.push(i);
      }
      setIsFormingSentencesQuestionEditCorrectAnswers(correctAnswers);
    } else {
      setIsFormingSentencesQuestionEdit(false);
    }
  }, [useSelectedQuestionTypeEdit, useOptionsEdit]);


  useEffect(() => {
    if(useSelectedTopicNew) {
      const activeTopic = useSelectedTopicNew;
      Request.get(`/chapter/byTopic/${activeTopic}`).then(chapterResponse => {
        const chapterData = chapterResponse.data;
        setChaptersNew(chapterData);
        const activeChapter = chapterData[0]?._id;
        setSelectedChapterNew(activeChapter);
        return chapterData;
      }).then(data => {
        handleHeaderChapter(data);
      });
    }
  }, [useSelectedTopicNew]);

  useEffect(() => {
    if(useSelectedTopicEdit) {
      const activeTopic = useSelectedTopicEdit;
      Request.get(`/chapter/byTopic/${activeTopic}`).then(chapterResponse => {
        const chapterData = chapterResponse.data;
        setChaptersEdit(chapterData);
        const activeChapter = chapterData[0]?._id;
        setSelectedChapterEdit(activeChapter);
      });
    }
  }, [useSelectedTopicEdit]);
  //! End Core Codes

  const handleIsFormingSentencesQuestionNewCorrectAnswers = (index, value) => {
    let correctAnswers = useIsFormingSentencesQuestionNewCorrectAnswers;
    correctAnswers[index] = parseInt(value);
    setIsFormingSentencesQuestionNewCorrectAnswers([...correctAnswers]);
  }

  const handleIsFormingSentencesQuestionEditCorrectAnswers = (index, value) => {
    let correctAnswers = useIsFormingSentencesQuestionEditCorrectAnswers;
    correctAnswers[index] = parseInt(value);
    setIsFormingSentencesQuestionEditCorrectAnswers([...correctAnswers]);
  }

  const increaseOptionCount = () => {
    const options = useOptions;
    options.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptions([...options]);
    const optionsOptions = useOptionsOptions;
    optionsOptions.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptionsOptions([...optionsOptions]);
  }
  const removeFromOptions = (index) => {
    if(useOptions.length > 0) {
      useOptions.splice(index, 1);
      setOptions([...useOptions]);
    }
  }
  const handleOptionsOptions = (index, option, value) => {
    const optionsOptions = useOptionsOptions;
    optionsOptions[index][option] = value;
    setOptionsOptions([...optionsOptions]);
  }
  const increaseColorOptionCount = () => {
    const colorOptions = useColorOptions;
    colorOptions.push("");
    setColorOptions([...colorOptions]);
  };
  const removeFromColorOptions = (index) => {
    if(useColorOptions.length > 0) {
      useColorOptions.splice(index, 1);
      setColorOptions([...useColorOptions]);
    }
  };
  const handleColorOptions = (index, value) => {
    const colorOptions = useColorOptions;
    colorOptions[index] = value;
    setColorOptions([...colorOptions]);
  };
  const increaseColorOptionCountEdit = () => {
    const colorOptions = useColorOptionsEdit;
    colorOptions.push("");
    setColorOptionsEdit([...colorOptions]);
  };
  const removeFromColorOptionsEdit = (index) => {
    if(useColorOptionsEdit.length > 0) {
      useColorOptionsEdit.splice(index, 1);
      setColorOptionsEdit([...useColorOptionsEdit]);
    }
  };
  const handleColorOptionsEdit = (index, value) => {
    const colorOptions = useColorOptionsEdit;
    colorOptions[index] = value;
    setColorOptionsEdit([...colorOptions]);
  };

  const increaseOptionCountEdit = () => {
    const options = useOptionsEdit;
    options.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptionsEdit([...options]);
    const optionsOptions = useOptionsOptionsEdit;
    optionsOptions.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptionsOptionsEdit([...optionsOptions]);
  }
  const removeFromOptionsEdit = (index) => {
    if(useOptionsEdit.length > 0) {
      useOptionsEdit.splice(index, 1);
      setOptionsEdit([...useOptionsEdit]);
    }
  }
  const handleOptionsOptionsEdit = (index, option, value) => {
    const optionsOptions = useOptionsOptionsEdit;
    optionsOptions[index][option] = value;
    setOptionsOptionsEdit([...optionsOptions]);
  }

  const addCorrectAnswerText = () => {
    setCorrectAnswerTexts([...useCorrectAnswerTexts, '']);
  };
  
  const removeCorrectAnswerText = (index) => {
    const newTexts = useCorrectAnswerTexts.filter((_, i) => i !== index);
    setCorrectAnswerTexts(newTexts);
  };
  
  const handleCorrectAnswerTextChange = (index, value) => {
    const newTexts = useCorrectAnswerTexts.map((text, i) => (i === index ? value : text));
    setCorrectAnswerTexts(newTexts);
  };

  const addCorrectAnswerTextEdit = () => {
    setCorrectAnswerTextsEdit([...useCorrectAnswerTextsEdit, '']);
  };

  const removeCorrectAnswerTextEdit = (index) => {
    const newTexts = useCorrectAnswerTextsEdit.filter((_, i) => i !== index);
    setCorrectAnswerTextsEdit(newTexts);
  };

  const handleCorrectAnswerTextEditChange = (index, value) => {
    const newTexts = useCorrectAnswerTextsEdit.map((text, i) => (i === index ? value : text));
    setCorrectAnswerTextsEdit(newTexts);
  };

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      const objectId = row?._id;
      if(objectId) {
        const newOrder = newValue;
        Request.post(`/${url}/move/${objectId}/${newOrder}`, {}).then(response => {
          fetchEntire();
        });
      }
    },
  });

  const noCorrectAnswerTypes = [
    'painting',
  ];

  const multipleCorrectAnswerTypes = [
    'zeppeline',
    'letter',
    'objectWithStartingLetter',
  ];


  //! renders
  

  
  return (
    <React.Fragment>
      <PageHeader
        pageTitle={pageTitle}
        objectCount={useAllResponse.length}
        objectName="soru"
        cols={useCols}
        tableData={useTableData}
        cellEdit={cellEdit}
        toggleNewModal={toggleNewModal}
        />

      <XModal 
        title="Yeni Soru Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
        size="xl"
        fullScreen={true}
      >
        <form>
          <SelectInput
            name="lesson"
            label="Ders"
            value={useActiveNewItem["lesson"] ? useActiveNewItem["lesson"] : null}
            onChange={(e) => {
              setActiveNewItem({...useActiveNewItem, [e.target.name]: e.target.value});
              setSelectedLessonNew(e.target.value);
              setSetLanguageNew(useLessons.find(lesson => lesson._id === e.target.value)?.abbr);
            }}
            options={useLessons}
            />
          <SelectInput
            name="topic"
            label="Konu"
            value={useActiveNewItem["topic"] ? useActiveNewItem["topic"] : null}
            onChange={(e) => {
              setActiveNewItem({...useActiveNewItem, [e.target.name]: e.target.value}) ;
              setSelectedTopicNew(e.target.value);
            }}
            options={useTopicsNew}
            />
          <SelectInput
            name="chapter"
            label="Bölüm"
            value={useActiveNewItem["chapter"] ? useActiveNewItem["chapter"] : null}
            onChange={(e) => setSelectedChapterNew(e.target.value)}
            options={useChaptersNew}
            />
          <NumberInput
            name="questionPoint"
            label="Puan"
            value={useQuestionPoint ? useQuestionPoint : 0}
            onChange={(e) => setQuestionPoint(e.target.value)}
            />
          <SelectInput
            name="questionType"
            label="Soru Tipi"
            value={useSelectedQuestionTypeNew ? useSelectedQuestionTypeNew : null}
            onChange={(e) => { setSelectedQuestionTypeNew(e.target.value); } }
            options={questionTypes}
            isObject={true}
            />
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">Soru</Label>
            <div className="col-sm-11" style={formInput}>
              <InlineCheckboxInput
                name="hasPictureNew"
                label="Resimli Soru"
                value={useHasPictureNew}
                onChange={(e) => setHasPictureNew(e.target.checked)}
                />
              <InlineCheckboxInput
                name="hasVoiceNew"
                label="Sesli Soru"
                value={useHasVoiceNew}
                onChange={(e) => setHasVoiceNew(e.target.checked)}
                />
              <X xIf={!['painting'].includes(useSelectedQuestionTypeNew)}>
              <InlineCheckboxInput
                name="hasTextNew"
                label="Metinli Soru"
                value={useHasTextNew}
                onChange={(e) => setHasTextNew(e.target.checked)}
                />
              </X>
              <X xIf={['painting'].includes(useSelectedQuestionTypeNew)}>
              <InlineCheckboxInput
                name="isPaintBucket"
                label="Paint Bucket aracı kullanılır"
                value={useIsPaintBucket}
                onChange={(e) => setIsPaintBucket(e.target.checked)}
                />
              </X>
              {useHasPictureNew && (
                <ImageInput
                  name="pictureNew"
                  label="Resim"
                  useStateFileId={usePictureNewFileId}
                  setStateFileId={setPictureNewFileId}
                  alt={useActiveNewItem["name"]}
                  />
              )}
              {useHasVoiceNew && (
                <Text2SpeechInput
                  name="voiceTextNew"
                  label="Ses"
                  useStateFileId={useVoiceId}
                  setStateFileId={setVoiceId}
                  />
              )}
              {useHasTextNew && (
                <TextareaInputInRow
                  name="textNew"
                  label="Metin"
                  useState={useTextNew}
                  setState={setTextNew}
                  />
              )}
            </div>
          </div>
            
          <X xIf={!['painting'].includes(useSelectedQuestionTypeNew)}>
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">
              Seçenekler
              <Button style={{marginLeft:"5px"}} color="primary" size="sm" onClick={increaseOptionCount}>+</Button>
            </Label>
            <div className="col-sm-11" style={formInput}>
              {useOptions.map((option, index) => (
                  <Row style={{marginBottom: "10px"}}>
                    <Col>
                      <Row>
                        <Col style={{fontSize:"1rem", fontWeight:"bold"}}>
                          {index + 1}. Seçenek
                          <Button style={{marginLeft:"5px"}} color="danger" size="sm" 
                            onClick={() => removeFromOptions(index)}
                          >
                            <i className="mdi mdi-trash-can"></i>
                          </Button>
                        </Col>
                      </Row>
                      <InlineCheckboxInput
                        name={`hasPictureNew${index}`}
                        label="Resimli Seçenek"
                        value={useOptionsOptions[index]?.hasPicture}
                        onChange={(e) => handleOptionsOptions(index, "hasPicture", e.target.checked)}
                        />
                      <InlineCheckboxInput
                        name={`hasVoiceNew${index}`}
                        label="Sesli Seçenek"
                        value={useOptionsOptions[index]?.hasVoice}
                        onChange={(e) => handleOptionsOptions(index, "hasVoice", e.target.checked)}
                        />
                      <InlineCheckboxInput
                        name={`hasTextNew${index}`}
                        label="Metinli Seçenek"
                        value={useOptionsOptions[index]?.hasText}
                        onChange={(e) => handleOptionsOptions(index, "hasText", e.target.checked)}
                        />
                      {useOptionsOptions[index]?.hasPicture && (
                        <ImageInput
                          name={`optionPictureNew${index}`}
                          label="Resim"
                          useStateFileId={useOptionsPictureIds[index]}
                          setStateFn={(responseId) => {
                            useOptionsPictureIds[index] = responseId;
                            setOptionsPictureIds([...useOptionsPictureIds]);
                          }}
                          alt={useActiveNewItem["name"]}
                          />
                      )}
                      {useOptionsOptions[index]?.hasVoice && (
                        <Text2SpeechInput
                          name={`optionVoiceNew${index}`}
                          label="Ses"
                          useStateFileId={useOptionsVoiceIds[index]}
                          setStateFn={(responseId) => {
                            useOptionsVoiceIds[index] = responseId;
                            setOptionsVoiceIds([...useOptionsVoiceIds]);
                          }}
                          />
                      )}
                      {useOptionsOptions[index]?.hasText && (
                        <TextInputInRow
                          name={`optionTextNew${index}`}
                          label="Metin"
                          useState={useOptionsTexts}
                          setState={setOptionsTexts}
                          />
                      )}
                    </Col>
                  </Row>
              ))}
            </div>
          </div>
          </X>
          <X xIf={['painting'].includes(useSelectedQuestionTypeNew)}>
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">
              Renk Seçenekleri
              <Button style={{marginLeft:"5px"}} color="primary" size="sm" onClick={increaseColorOptionCount}>+</Button>
            </Label>
            <div className="col-sm-11" style={formInput}>
              <Row style={{marginBottom: "10px", justifyContent: "flex-start"}}>
                {useColorOptions.map((option, index) => (
                  <Col xs={6} md={2} lg={1}>
                    <ColorInput
                      name={`colorOption${index}`}
                      label={`${index + 1}. Renk`}
                      value={useColorOptions[index]}
                      onChange={(e) => handleColorOptions(index, e.target.value)}
                      removeFn={() => removeFromColorOptions(index)}
                      />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          </X>
          

        {isIn(useSelectedQuestionTypeNew, noCorrectAnswerTypes) ? <></> 
        : isIn(useSelectedQuestionTypeNew, multipleCorrectAnswerTypes) ? (
          <div className="form-group row mb-2">
            <Label for="chapter" className="col-sm-1 col-form-label">Doğru Cevap</Label>
            <div className="col-sm-11" style={formInput}>
              <Row>
                {useOptions.map((option, index) => (
                  <Col>
                    <Label for={`correctAnswer${index}`} className="col-sm-1 col-form-label">{index + 1}</Label>
                    <div className="col-sm-11" style={formInput}>
                      <Input
                        name={`correctAnswer${index}`}
                        id={`correctAnswer${index}`}
                        className="form-control"
                        type="select"
                        value={useIsFormingSentencesQuestionNewCorrectAnswers[index]}
                        onChange={(e) => handleIsFormingSentencesQuestionNewCorrectAnswers(index, e.target.value)}
                      >
                        {useOptions.map((option, index) => (
                          <option value={index}>
                            {index + 1} 
                            {useOptionsTexts[index] ? useOptionsTexts[index] : ""} 
                            {useOptionsPictureIds[index] ? imageFromURL(useOptionsPictureIds[index]) : ""}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )
        :(
          <div className="form-group row mb-2">
            <Label for="chapter" className="col-sm-1 col-form-label">Doğru Cevap</Label>
            {useIsFormingSentencesQuestionNew ?
            (
              <div className="col-sm-11" style={formInput}>
                <Row>
                  {useOptions.map((option, index) => (
                    <Col>
                      <Label for={`correctAnswer${index}`} className="col-sm-1 col-form-label">{index + 1}</Label>
                      <div className="col-sm-11" style={formInput}>
                        <Input
                          name={`correctAnswer${index}`}
                          id={`correctAnswer${index}`}
                          className="form-control"
                          type="select"
                          value={useIsFormingSentencesQuestionNewCorrectAnswers[index]}
                          onChange={(e) => handleIsFormingSentencesQuestionNewCorrectAnswers(index, e.target.value)}
                        >
                          {useOptions.map((option, index) => (
                            <option value={index}>{index + 1} - {useOptionsTexts[index]}</option>
                          ))}
                        </Input>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="col-sm-11" style={formInput}>
                <InlineCheckboxInput
                  name="correctAnswerHasIndex"
                  label="Index"
                  value={useCorrectAnswerHasIndex}
                  onChange={(e) => setCorrectAnswerHasIndex(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasText"
                  label="Metin"
                  value={useCorrectAnswerHasText}
                  onChange={(e) => setCorrectAnswerHasText(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasFile"
                  label="Dosya"
                  value={useCorrectAnswerHasFile}
                  onChange={(e) => setCorrectAnswerHasFile(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasVoiceNew"
                  label="Ses"
                  value={useCorrectAnswerHasVoiceNew}
                  onChange={(e) => setCorrectAnswerHasVoiceNew(e.target.checked)}
                  />
                {useCorrectAnswerHasIndex && (
                  <SelectInputColIndex
                    name="correctAnswerIndex"
                    label="Index"
                    value={useCorrectAnswerIndex ? useCorrectAnswerIndex : null}
                    onChange={(e) => setCorrectAnswerIndex(e.target.value)}
                    options={useOptions}
                    />
                )}
                {useCorrectAnswerHasText && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerText" className="col-sm-1 col-form-label">
                        Metinler
                        <Button style={{marginLeft:"5px", cursor:'pointer', }} color="primary" size="sm" onClick={addCorrectAnswerText}>+</Button>
                      </Label>
                      <Row>
                        <Col>
                          {useCorrectAnswerTexts.map((text, index) => (
                            <Row style={{margin: "10px 0"}}>
                              <div key={index} className="col-sm-8">
                                <Input
                                  name={`correctAnswerText${index}`}
                                  id={`correctAnswerText${index}`}
                                  type="text"
                                  value={text}
                                  className=""
                                  onChange={(e) => handleCorrectAnswerTextChange(index, e.target.value)}
                                />
                              </div>
                              <div className="col-sm-1">
                                <Button color="danger" onClick={() => removeCorrectAnswerText(index)}>
                                  <i className="mdi mdi-trash-can"></i>
                                </Button>
                              </div>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {useCorrectAnswerHasFile && (
                  <FileInputInRow
                    name="correctAnswerFile"
                    label="Dosya"
                    useState={useCorrectAnswerFile}
                    setState={setCorrectAnswerFile}
                    />
                )}
                {useCorrectAnswerHasVoiceNew && (
                  <Text2SpeechInput
                    name="correctAnswerVoiceTextNew"
                    label="Ses"
                    useStateFileId={useCorrectAnswerVoiceIdNew}
                    setStateFileId={setCorrectAnswerVoiceIdNew}
                    />
                )}
              </div>
            )}

          </div>
        )}

        </form>
      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItem?.chapter?.name + " içindeki soru"} 
        showModal={useEditModal} 
        toggle={toggleEditModal} 
        saveButton={"Güncelle"} 
        onSubmit={()=>{handleEdit();}}
        size="xl"
        fullScreen={true}
      >
        <form>
          <SelectInput
            name="lesson"
            label="Ders"
            value={useActiveEditItem["lesson"] ? useActiveEditItem["lesson"] : null}
            onChange={(e) => {
              setActiveEditItem({...useActiveEditItem, [e.target.name]: e.target.value});
              setSelectedLessonEdit(e.target.value);
              setSetLanguageEdit(useLessons.find(lesson => lesson._id === e.target.value)?.abbr);
            }}
            options={useLessons}
            />
          <SelectInput
            name="topic"
            label="Konu"
            value={useActiveEditItem["topic"] ? useActiveEditItem["topic"] : null}
            onChange={(e) => {
              setActiveEditItem({...useActiveEditItem, [e.target.name]: e.target.value}) ;
              setSelectedTopicEdit(e.target.value);
            }}
            options={useTopicsEdit}
            />
          <SelectInput
            name="chapter"
            label="Bölüm"
            value={useActiveEditItem["chapter"] ? useActiveEditItem["chapter"] : null}
            onChange={(e) => setSelectedChapterEdit(e.target.value)}
            options={useChaptersEdit}
            />
          <NumberInput
            name="questionPoint"
            label="Puan"
            value={useQuestionPointEdit ? useQuestionPointEdit : 0}
            onChange={(e) => setQuestionPointEdit(e.target.value)}
            />
          <SelectInput
            name="questionType"
            label="Soru Tipi"
            value={useSelectedQuestionTypeEdit ? useSelectedQuestionTypeEdit : null}
            onChange={(e) => { setSelectedQuestionTypeEdit(e.target.value); } }
            options={questionTypes}
            isObject={true}
            />
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">Soru</Label>
            <div className="col-sm-11" style={formInput}>
              <InlineCheckboxInput
                name="hasPictureEdit"
                label="Resimli Soru"
                value={useHasPictureEdit}
                onChange={(e) => setHasPictureEdit(e.target.checked)}
                />
              <InlineCheckboxInput
                name="hasVoiceEdit"
                label="Sesli Soru"
                value={useHasVoiceEdit}
                onChange={(e) => setHasVoiceEdit(e.target.checked)}
                />
              <X xIf={!['painting'].includes(useSelectedQuestionTypeEdit)}>
              <InlineCheckboxInput
                name="hasTextEdit"
                label="Metinli Soru"
                value={useHasTextEdit}
                onChange={(e) => setHasTextEdit(e.target.checked)}
                />
              </X>
              <X xIf={['painting'].includes(useSelectedQuestionTypeEdit)}>
              <InlineCheckboxInput
                name="isPaintBucketEdit"
                label="Paint Bucket aracı kullanılır"
                value={useIsPaintBucketEdit}
                onChange={(e) => setIsPaintBucketEdit(e.target.checked)}
                />
              </X>
              {useHasPictureEdit && (
                <ImageInput
                  name="pictureEdit"
                  label="Resim"
                  useStateFileId={usePictureEditFileId}
                  setStateFileId={setPictureEditFileId}
                  alt={useActiveEditItem["name"]}
                  />
              )}
              {useHasVoiceEdit && (
                <Text2SpeechInput
                  name="voiceTextEdit"
                  label="Ses"
                  useStateFileId={useVoiceIdEdit}
                  setStateFileId={setVoiceIdEdit}
                  />
              )}
              {useHasTextEdit && (
                <TextareaInputInRow
                  name="textEdit"
                  label="Metin"
                  useState={useTextEdit}
                  setState={setTextEdit}
                  />
              )}
            </div>
          </div>

          <X xIf={!['painting'].includes(useSelectedQuestionTypeEdit)}>
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">
              Seçenekler
              <Button style={{marginLeft:"5px"}} color="primary" size="sm" onClick={increaseOptionCountEdit}>+</Button>
            </Label>
            <div className="col-sm-11" style={formInput}>
              {useOptionsEdit.map((option, index) => (
                  <Row style={{marginBottom: "10px"}}>
                    <Col>
                      <Row>
                        <Col style={{fontSize:"1rem", fontWeight:"bold"}}>
                          {index + 1}. Seçenek
                          <Button style={{marginLeft:"5px"}} color="danger" size="sm"
                            onClick={() => removeFromOptionsEdit(index)}
                          >
                            <i className="mdi mdi-trash-can"></i>
                          </Button>
                        </Col>
                      </Row>
                      <InlineCheckboxInput
                        name={`hasPictureEdit${index}`}
                        label="Resimli Seçenek"
                        value={useOptionsOptionsEdit[index]?.hasPicture}
                        onChange={(e) => handleOptionsOptionsEdit(index, "hasPicture", e.target.checked)}
                        />
                      <InlineCheckboxInput
                        name={`hasVoiceEdit${index}`}
                        label="Sesli Seçenek"
                        value={useOptionsOptionsEdit[index]?.hasVoice}
                        onChange={(e) => handleOptionsOptionsEdit(index, "hasVoice", e.target.checked)}
                        />
                      <InlineCheckboxInput
                        name={`hasTextEdit${index}`}
                        label="Metinli Seçenek"
                        value={useOptionsOptionsEdit[index]?.hasText}
                        onChange={(e) => handleOptionsOptionsEdit(index, "hasText", e.target.checked)}
                        />
                      {useOptionsOptionsEdit[index]?.hasPicture && (
                        <ImageInput
                          name={`optionPictureEdit${index}`}
                          label="Resim"
                          useStateFileId={useOptionsPictureIdsEdit[index]}
                          setStateFn={(responseId) => {
                            useOptionsPictureIdsEdit[index] = responseId;
                            setOptionsPictureIdsEdit([...useOptionsPictureIdsEdit]);
                          }}
                          alt={useActiveEditItem["name"]}
                          />
                      )}
                      {useOptionsOptionsEdit[index]?.hasVoice && (
                        <Text2SpeechInput
                          name={`optionVoiceEdit${index}`}
                          label="Ses"
                          useStateFileId={useOptionsVoiceIdsEdit[index]}
                          setStateFn={(responseId) => {
                            useOptionsVoiceIdsEdit[index] = responseId;
                            setOptionsVoiceIdsEdit([...useOptionsVoiceIdsEdit]);
                          }}
                          />
                      )}
                      {useOptionsOptionsEdit[index]?.hasText && (
                        <TextInputInRow
                          name={`optionTextEdit${index}`}
                          label="Metin"
                          useState={useOptionsTextsEdit}
                          setState={setOptionsTextsEdit}
                          />
                      )}
                    </Col>
                  </Row>
              ))}
            </div>
          </div>
          </X>
          <X xIf={['painting'].includes(useSelectedQuestionTypeEdit)}>
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">
              Renk Seçenekleri
              <Button style={{marginLeft:"5px"}} color="primary" size="sm" onClick={increaseColorOptionCountEdit}>+</Button>
            </Label>
            <div className="col-sm-11" style={formInput}>
              <Row style={{marginBottom: "10px", justifyContent: "flex-start"}}>
                {useColorOptionsEdit.map((option, index) => (
                  <Col xs={6} md={2} lg={1}>
                    <ColorInput
                      name={`colorOption${index}`}
                      label={`${index + 1}. Renk`}
                      value={useColorOptionsEdit[index]}
                      onChange={(e) => handleColorOptionsEdit(index, e.target.value)}
                      removeFn={() => removeFromColorOptionsEdit(index)}
                      />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          </X>

          <div className="form-group row mb-2">
            <Label for="chapter" className="col-sm-1 col-form-label">Doğru Cevap</Label>
            {useIsFormingSentencesQuestionEdit ? (
              <div className="col-sm-11" style={formInput}>
                <Row>
                  {useOptionsEdit.map((option, index) => (
                    <Col>
                      <Label for={`correctAnswerEdit${index}`} className="col-sm-1 col-form-label">{index + 1}</Label>
                      <div className="col-sm-11" style={formInput}>
                        <Input
                          name={`correctAnswerEdit${index}`}
                          id={`correctAnswerEdit${index}`}
                          className="form-control"
                          type="select"
                          value={useIsFormingSentencesQuestionEditCorrectAnswers[index]}
                          onChange={(e) => handleIsFormingSentencesQuestionEditCorrectAnswers(index, e.target.value)}
                        >
                          {useOptionsEdit.map((option, index) => (
                            <option value={index}>{index + 1} - {useOptionsTextsEdit[index]}</option>
                          ))}
                        </Input>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="col-sm-11" style={formInput}>
                <InlineCheckboxInput
                  name="correctAnswerHasIndexEdit"
                  label="Index"
                  value={useCorrectAnswerHasIndexEdit}
                  onChange={(e) => setCorrectAnswerHasIndexEdit(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasTextEdit"
                  label="Metin"
                  value={useCorrectAnswerHasTextEdit}
                  onChange={(e) => setCorrectAnswerHasTextEdit(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasFileEdit"
                  label="Dosya"
                  value={useCorrectAnswerHasFileEdit}
                  onChange={(e) => setCorrectAnswerHasFileEdit(e.target.checked)}
                  />
                <InlineCheckboxInput
                  name="correctAnswerHasVoiceEdit"
                  label="Ses"
                  value={useCorrectAnswerHasVoiceEdit}
                  onChange={(e) => setCorrectAnswerHasVoiceEdit(e.target.checked)}
                  />
                {useCorrectAnswerHasIndexEdit && (
                  <SelectInputColIndex
                    name="correctAnswerIndexEdit"
                    label="Index"
                    value={useCorrectAnswerIndexEdit ? useCorrectAnswerIndexEdit : null}
                    onChange={(e) => setCorrectAnswerIndexEdit(e.target.value)}
                    options={useOptionsEdit}
                    />
                )}
                {useCorrectAnswerHasTextEdit && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerTextEdit" className="col-sm-1 col-form-label">
                        Metinler
                        <Button style={{marginLeft:"5px", cursor:'pointer', }} color="primary" size="sm" onClick={addCorrectAnswerTextEdit}>+</Button>
                      </Label>
                      <Row>
                        <Col>
                          {useCorrectAnswerTextsEdit.map((text, index) => (
                            <Row style={{margin: "10px 0"}}>
                              <div key={index} className="col-sm-8">
                                <Input
                                  name={`correctAnswerTextEdit${index}`}
                                  id={`correctAnswerTextEdit${index}`}
                                  type="text"
                                  value={text}
                                  className=""
                                  onChange={(e) => handleCorrectAnswerTextEditChange(index, e.target.value)}
                                />
                              </div>
                              <div className="col-sm-1">
                                <Button color="danger" onClick={() => removeCorrectAnswerTextEdit(index)}>
                                  <i className="mdi mdi-trash-can"></i>
                                </Button>
                              </div>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {useCorrectAnswerHasFileEdit && (
                  <FileInputInRow
                    name="correctAnswerFileEdit"
                    label="Dosya"
                    useState={useCorrectAnswerFileEdit}
                    setState={setCorrectAnswerFileEdit}
                    />
                )}
                {useCorrectAnswerHasVoiceEdit && (
                  <Text2SpeechInput
                    name="correctAnswerVoiceTextEdit"
                    label="Ses"
                    useStateFileId={useCorrectAnswerVoiceIdEdit}
                    setStateFileId={setCorrectAnswerVoiceIdEdit}
                    />
                )}
              </div>
            )}

          </div>

        </form>
      </XModal> 

      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
        <div className="form-group row mb-2">
          <Label for="lesson" className="col-sm-3 col-form-label">Ders</Label>
          <div className="col-sm-9">
            <Input id="lesson" className="form-control" type="text" value={useActiveViewItem["lesson"]?.name} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="topic" className="col-sm-3 col-form-label">Konu</Label>
          <div className="col-sm-9">
            <Input id="topic" className="form-control" type="text" value={useActiveViewItem["topics"]?.name || "-"} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="chapter" className="col-sm-3 col-form-label">Bölüm</Label>
          <div className="col-sm-9">
            <Input id="chapter" className="form-control" type="text" value={useActiveViewItem["chapter"]?.name || "-"} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="point" className="col-sm-3 col-form-label">Puan</Label>
          <div className="col-sm-9">
            <Input id="point" className="form-control" type="text" value={useActiveViewItem["point"] || 0} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="questionType" className="col-sm-3 col-form-label">Soru Tipi</Label>
          <div className="col-sm-9">
            <Input id="questionType" className="form-control" type="text" value={useActiveViewItem["questionType"] || '-'} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="question" className="col-sm-3 col-form-label">Soru</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.question?.imageFiles && useActiveViewItem?.question?.imageFiles.length > 0 && (
              <>
              {imageFromURL(useActiveViewItem?.question?.imageFiles[0], {width: 50, height: 50}, {height:"50px", width:"auto"} )}
              <br />
              </>
            )}
            {useActiveViewItem?.question?.texts && useActiveViewItem?.question?.texts.length > 0 && (
              <>
              {useActiveViewItem?.question?.texts[0]}
              <br />
              </>
            )}
            {useActiveViewItem?.question?.voiceFiles && useActiveViewItem?.question?.voiceFiles.length > 0 && (
              <>
              <audio controls src={audioURL(useActiveViewItem?.question?.voiceFiles[0])} style={{height: "40px"}} />
              <br />
              </>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="options" className="col-sm-3 col-form-label">Seçenekler</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.options && useActiveViewItem?.options.length > 0 && (
              useActiveViewItem?.options.map((option, index) => (
                <div>
                  {option?.valueImageFile && (
                    <>
                    {imageFromURL(option?.valueImageFile, {width: 50, height: 50}, {height:"50px", width:"auto"} )}
                    <br />
                    </>
                  )}
                  {option?.value && (
                    <>
                    {option?.value}
                    <br />
                    </>
                  )}
                  {option?.valueVoiceFile && (
                    <>
                    <audio controls src={audioURL(option?.valueVoiceFile)} style={{height: "40px"}} />
                    <br />
                    </>
                  )}
                </div>
              ))
            )}
              
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="correctAnswer" className="col-sm-3 col-form-label">Doğru Cevap</Label>
          {useActiveViewItem?.questionType == "formingSentences" ? (
            <div className="col-sm-9">
              {useActiveViewItem?.correctAnswer?.customValue?.answer && (
                <>
                {useActiveViewItem?.correctAnswer?.customValue?.answer.map((answer, index) => (
                  <div>
                    {index + 1}. {useActiveViewItem?.options[answer]?.value}
                  </div>
                ))}
                </>
              )}
            </div>
          ) : (
            <div className="col-sm-9">
              {(useActiveViewItem?.correctAnswer?.index || useActiveViewItem?.correctAnswer?.index >= 0) && (
                <>
                Index: {useActiveViewItem?.correctAnswer?.index}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.text && (
                <>
                Metin: {useActiveViewItem?.correctAnswer?.text}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.texts && (
                <>
                Metinler: {useActiveViewItem?.correctAnswer?.texts.join(", ")}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.file && (
                <>
                Dosya: 
                {imageFromURL(useActiveViewItem?.correctAnswer?.file, {width: 50, height: 50}, {height:"50px", width:"auto"} )}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.voiceFile && (
                <>
                Ses: 
                <audio controls src={audioURL(useActiveViewItem?.correctAnswer?.voiceFile)} style={{height: "40px"}} />
                <br />
                </>
              )}
            </div>
          )}
        </div>

        
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={`${useActiveDeleteItem?.chapter?.name} içindeki soru`} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.chapter?.name} içindeki soruyu silmek istediğinizden emin misiniz?`}
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chapters);
