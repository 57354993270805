import React , {useEffect, useState} from "react"

import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Request } from "helpers/api_helper";

const Dashboard = (props) => {

  document.title = "Anasayfa";


  const breadcrumbItems = [
    { title: "Anasayfa", link: "#" }
  ]
  const [useLessonCount, setLessonCount] = useState(0);
  const [useTopicCount, setTopicCount] = useState(0);
  const [useChapterCount, setChapterCount] = useState(0);
  const [useQuestionCount, setQuestionCount] = useState(0);

  const fetchAll = () => {
    Request.get('/lesson').then(response => {
      setLessonCount(response.data.length);
    });
    Request.get('/topic').then(response => {
      setTopicCount(response.data.length);
    });
    Request.get('/chapter').then(response => {
      setChapterCount(response.data.objectCount);
    });
    Request.get('/question').then(response => {
      setQuestionCount(response.data.length);
    });
  };

  useEffect(() => {
    props.setBreadcrumbItems('Anasayfa' , breadcrumbItems)
  },)

  useEffect(() => {
    fetchAll();
  }, []);

  const reports = [
    { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" },
    { title: "Revenuee", iconClass: "buffer", total: "$46,782", average: "-29%", badgecolor: "danger" },
    { title: "Average Price", iconClass: "tag-text-outline", total: "$15.9", average: "0%", badgecolor: "warning" },
    { title: "Product Sold", iconClass: "briefcase-check", total: "1890", average: "+89%", badgecolor: "info" },
  ]
  const report = { title: "Orders", iconClass: "cube-outline", total: "1,587", average: "+11%", badgecolor: "info" }

  return (
    <React.Fragment>

      {/*mimi widgets */}
      {/* <Miniwidget reports={reports} /> */}
      <Row>
          <Col xl={3} sm={6} key={1}>
            <Card className="mini-stat bg-primary">
              <CardBody className="card-body mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={"float-end mdi mdi-book-alert"}></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-3 font-size-16 text-white">Ders Sayısı</h6>
                  <h2 className="mb-4 text-white">{useLessonCount}</h2>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} sm={6} key={1}>
            <Card className="mini-stat bg-primary">
              <CardBody className="card-body mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={"float-end mdi mdi-book-edit"}></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-3 font-size-16 text-white">Konu Sayısı</h6>
                  <h2 className="mb-4 text-white">{useTopicCount}</h2>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} sm={6} key={1}>
            <Card className="mini-stat bg-primary">
              <CardBody className="card-body mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={"float-end mdi mdi-animation"}></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-3 font-size-16 text-white">Bölüm Sayısı</h6>
                  <h2 className="mb-4 text-white">{useChapterCount}</h2>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} sm={6} key={1}>
            <Card className="mini-stat bg-primary">
              <CardBody className="card-body mini-stat-img">
                <div className="mini-stat-icon">
                  <i className={"float-end mdi mdi-help"}></i>
                </div>
                <div className="text-white">
                  <h6 className="text-uppercase mb-3 font-size-16 text-white">Soru Sayısı</h6>
                  <h2 className="mb-4 text-white">{useQuestionCount}</h2>
                </div>
              </CardBody>
            </Card>
          </Col>
      </Row>

      

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard);