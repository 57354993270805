import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DataTable from "components/Custom/DataTable";
import { HTTP, imageFromURL, uploadFile, uploadVideo, Request } from "helpers/api_helper";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Progress, Row, Spinner, Tooltip } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { filter } from "lodash";
import { badger } from "components/Custom/XBadge";
import X from "components/Custom/X";

const dataCols = [
  {
    dataField: "name",
    text: "İsim",
    type: "text",
  },
  {
    dataField: "abbr",
    text: "Kısaltma",
    type: "text",
  },
  {
    dataField: "logo",
    text: "Logo",
    type: "image",
  },
];

const formInput = {
  marginTop: "0.5rem",
};

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...dataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];
const url = "lesson";
const pageTitle = "Dersler";
const modelPluralName = "Dersler";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Dersler", url: "/dersler" },
];

const Lessons = props => {
  document.title = props.title || "Dersler";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useLessons, setLessons] = useState([]);
  const [useAllResponse, setAllResponse] = useState({});

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };
  const toggleEditModal = (item) => {
    if(item) {
      setActiveEditItem(item);
      setActiveEditItemName(item.name);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setMultiModal(!useMultiModal);
  }

  const handleEditItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveNewItem({
        ...useActiveNewItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveNewItem({
        ...useActiveNewItem,
        [e.target.name]: e.target.value,
      });
    }

    // if(e.target.name === "lesson") {
    //   setCurrentLesson(useLessons.find((lesson) => lesson._id === e.target.value));
    // }
  };

  const handleEdit = (activeItem) => {
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols.filter((dataCol) => dataCol.isForeign !== true)) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveEditItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveEditItem[key];
        }
      });
      Request.put(`/${url}/${activeItem?._id}`, activeItem).then(response => {
        Request.get(`/${url}`).then(response => {
          return response.data;
        }).then(data => {
          fetchEntire();
        }).then(() => {
          toggleEditModal();
        });
      });
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.name === "parentTopic" && useActiveNewItem[dataCol.dataCol.name] === "") {
        delete useActiveNewItem[dataCol.dataField];
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveNewItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveNewItem[key];
        }
      });
      Request.post(`/${url}`, useActiveNewItem).then(response => {
        Request.get(`/${url}`).then(response => {
          return response.data;
        }).then(data => {
          fetchEntire();
        }).then(() => {
          toggleNewModal();
          setActiveNewItem({});
        });
      });
    });
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`).then(response => {
      Request.get(`/${url}`).then(response => {
        return response.data;
      }).then(data => {
        fetchEntire();
      }).then(() => {
        toggleDeleteModal();
      });
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    data = data;
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        console.log('im', item.logo);
        return {
          num: index + 1,
          name: item.name,
          abbr: item.abbr,
          logo: imageFromURL(item.logo._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, item.name),
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={()=>{toggleViewModal(item)}}
              onEdit={()=>{toggleEditModal(item)}}
              onDelete={()=>{toggleDeleteModal(item)}}
            />,
        }
      });
      return mapped;
    }
    return [];
  };
  const fetchLessons = () => {
    Request.get("/lesson").then(response => {
      setLessons(response.data);
    });
  };

  useEffect(() => {
    console.log("useDataTableData", useDataTableData);
  }, [useTableData]);

  const fetchEntire = async () => {
    Request.get(`/${url}`).then(response => {
      setDataTableData(response.data);
      setAllResponse(response.data);
      return response.data
    }).then(data => {
      const fetchedTableData = fetchTableData(data);
      setTableData(fetchedTableData);
      return fetchedTableData;
    }).then(() => {
      fetchLessons();
    }); 
  }

  useEffect(() => {
    fetchEntire();
  }, []);
  //! End Core Codes
  
  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                      Yeni Ekle
                    </Button>
                  </CardTitle>
                  Toplam {useAllResponse.objectCount} ders bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="name" className="col-sm-3 col-form-label">İsim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                className="form-control"
                type="text"
                value={useActiveNewItem["name"] ? useActiveNewItem["name"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="abbr" className="col-sm-3 col-form-label">Kısaltma</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="abbr"
                className="form-control"
                type="text"
                value={useActiveNewItem["abbr"] ? useActiveNewItem["abbr"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="logo" className="col-sm-3 col-form-label">Logo</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="logo"
                className="form-control"
                type="file"
                target={useActiveNewItem["logo"] ? useActiveNewItem["logo"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
        <div className="form-group row mb-2">
          <Label for="name" className="col-sm-3 col-form-label">İsim</Label>
          <div className="col-sm-9">
            <Input id="name" className="form-control" type="text" value={useActiveViewItem["name"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="abbr" className="col-sm-3 col-form-label">Kısaltma</Label>
          <div className="col-sm-9">
            <Input id="abbr" className="form-control" type="text" value={useActiveViewItem["abbr"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="logo" className="col-sm-3 col-form-label">Logo</Label>
          <div className="col-sm-9">
            {imageFromURL(useActiveViewItem["logo"]?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveViewItem["name"])}
          </div>
        </div>
        
      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="name" className="col-sm-3 col-form-label">İsim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                className="form-control"
                type="text"
                value={useActiveEditItem["name"] ? useActiveEditItem["name"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="abbr" className="col-sm-3 col-form-label">Kısaltma</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="abbr"
                className="form-control"
                type="text"
                value={useActiveEditItem["abbr"] ? useActiveEditItem["abbr"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="logo" className="col-sm-3 col-form-label">Logo</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="logo"
                className="form-control"
                type="file"
                target={useActiveEditItem["logo"] ? useActiveEditItem["logo"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          
        </form>
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.name} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.name}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lessons);
