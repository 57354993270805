import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import X from './X';

const ActionsCol = ({ id, onView=null, onEdit=null, onDelete=null, onMoveUp=null, onMoveDown=null }) => {
    const [useTooltipView, setTooltipView] = useState(false);
    const [useTooltipEdit, setTooltipEdit] = useState(false);
    const [useTooltipDelete, setTooltipDelete] = useState(false);
    const [useTooltipMoveUp, setTooltipMoveUp] = useState(false);
    const [useTooltipMoveDown, setTooltipMoveDown] = useState(false);
    
    const toggleView = () => setTooltipView(!useTooltipView);
    const toggleEdit = () => setTooltipEdit(!useTooltipEdit);
    const toggleDelete = () => setTooltipDelete(!useTooltipDelete);
    const toggleMoveUp = () => setTooltipMoveUp(!useTooltipMoveUp);
    const toggleMoveDown = () => setTooltipMoveDown(!useTooltipMoveDown);
    return (
        <div className="d-flex">
            <X xIf={onView}>
                <Button id={"view-"+id} className="btn btn-sm btn-success" onClick={() => onView(id)}>
                    <i className="mdi mdi-eye"></i>
                </Button>
                <Tooltip 
                    placement="top" 
                    isOpen={useTooltipView} 
                    target={"view-"+id} 
                    toggle={toggleView}
                >
                    Gözat
                </Tooltip>
            </X>
            <X xIf={onEdit}>
                <Button id={"edit-"+id} className="btn btn-sm btn-primary ms-2" onClick={() => onEdit(id)}>
                    <i className="mdi mdi-pencil"></i>
                </Button>
                <Tooltip 
                    placement="top" 
                    isOpen={useTooltipEdit} 
                    target={"edit-"+id}
                    toggle={toggleEdit}
                >
                    Düzenle
                </Tooltip>
            </X>
            <X xIf={onDelete}>
                <Button id={"delete-"+id} className="btn btn-sm btn-danger ms-2" onClick={() => onDelete(id)}>
                    <i className="mdi mdi-trash-can-outline"></i>
                </Button>
                <Tooltip 
                    placement="top" 
                    isOpen={useTooltipDelete} 
                    target={"delete-"+id}
                    toggle={toggleDelete}
                >
                    Sil
                </Tooltip>
            </X>
            <X xIf={onMoveUp}>
                <Button id={"move-up-"+id} className="btn btn-sm btn-primary ms-2" onClick={() => onMoveUp(id)}>
                    <i className="mdi mdi-arrow-up"></i>
                </Button>
            </X>
            <X xIf={onMoveDown}>
                <Button id={"move-down-"+id} className="btn btn-sm btn-warning ms-2" onClick={() => onMoveDown(id)}>
                    <i className="mdi mdi-arrow-down"></i>
                </Button>
            </X>
        </div>
    );
}

export default ActionsCol;
