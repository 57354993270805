import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DataTable from "components/Custom/DataTable";
import { HTTP, imageFromURL, uploadFile, uploadVideo, Request, audioURL } from "helpers/api_helper";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Progress, Row, Spinner, Tooltip } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { filter } from "lodash";
import { badger } from "components/Custom/XBadge";
import X from "components/Custom/X";
import MainAlert from "components/Custom/MainAlert";

const dataCols = [
  {
    dataField: "type",
    text: "Tip",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "title",
    text: "Başlık",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "content",
    text: "Açıklama",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "imageFile",
    text: "Resim Dosyası",
    type: "image",
  },
  {
    dataField: "voiceFile",
    text: "Ses Dosyası",
    type: "file",
  },
  
];

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...dataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const url = "post";
const pageTitle = "Post'lar";
const modelPluralName = "Post'lar";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Postlar", url: "/postlar" },
];

const postTypes = [
  {name: "Hikaye", value: "story"},
  {name: "Şarkı", value: "song"},
];

const Stories = props => {
  document.title = props.title || "Postlar";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useAllResponse, setAllResponse] = useState({});
  const [useSelectedTypeNew, setSelectedTypeNew] = useState("story");
  const [useSelectedTypeEdit, setSelectedTypeEdit] = useState("story");

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };
  const toggleEditModal = (item) => {
    if(item) {
      setActiveEditItem(item);
      setActiveEditItemName(item.title);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setMultiModal(!useMultiModal);
  }
  
  const handleEditItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };

  const handleEdit = (activeItem) => {
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols.filter((dataCol) => dataCol.isForeign !== true)) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.type === "file" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "file" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveEditItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveEditItem[key];
        }
      });
      Request.put(`/${url}/${activeItem?._id}`, activeItem).then(response => {
        Request.get(`/${url}`).then(response => {
          return response.data;
        }).then(data => {
          fetchEntire();
        }).then(() => {
          toggleEditModal();
        });
      });
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    console.log("ACTIVE NEW ITEM", useActiveNewItem);
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.type === "file" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } 
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveNewItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveNewItem[key];
        }
      });
      Request.post(`/${url}`, useActiveNewItem).then(response => {
        Request.get(`/${url}`).then(response => {
          return response.data;
        }).then(data => {
          fetchEntire();
        }).then(() => {
          toggleNewModal();
          setActiveNewItem({});
        });
      });
    });
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`).then(response => {
      Request.get(`/${url}`).then(response => {
        return response.data;
      }).then(data => {
        fetchEntire();
      }).then(() => {
        toggleDeleteModal();
      });
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    console.log("DATA", data);
    data = data;
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        return {
          num: index + 1,
          type: postTypes.find((postType) => postType.value === item.type)?.name,
          title: item.title,
          content: item.content,
          imageFile: imageFromURL(item?.imageFile?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, item?.title) || "",
          // voiceFile: item?.voiceFile?._id,
          voiceFile: item?.voiceFile?._id ? <audio controls><source src={audioURL(item?.voiceFile?._id)} type="audio/mpeg" /></audio> : "",
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={()=>{toggleViewModal(item)}}
              onEdit={()=>{toggleEditModal(item)}}
              onDelete={()=>{toggleDeleteModal(item)}}
            />,
        }
      });
      console.log("MAPPED", mapped);
      return mapped;
    }
    return [];
  };

  const fetchEntire = async () => {
    Request.get(`/${url}`).then(response => {
      response.data = response.data;
      setDataTableData(response.data);
      setAllResponse(response.data);
      return response.data
    }).then(data => {
      const fetchedTableData = fetchTableData(data);
      setTableData(fetchedTableData);
      return fetchedTableData;
    }); 
  }

  useEffect(() => {
    fetchEntire();
  }, []);
  //! End Core Codes
  
  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                      Yeni Ekle
                    </Button>
                  </CardTitle>
                  Toplam {useAllResponse?.length} post bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="type" className="col-sm-3 col-form-label">Tip</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="type"
                id="type"
                className="form-control"
                type="select"
                required={true}
                value={useSelectedTypeNew}
                onChange={handleNewItemChange}
              >
                {postTypes.map((postType, index) => {
                  return (
                    <option key={index} value={postType.value}>{postType.name}</option>
                  )
                })}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="title"
                id="title"
                className="form-control"
                type="text"
                required={true}
                value={useActiveNewItem["title"] ? useActiveNewItem["title"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="content" className="col-sm-3 col-form-label">Açıklama</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="content"
                id="content"
                className="form-control"
                type="textarea"
                style={{height: "100px"}}
                target={useActiveNewItem["content"] ? useActiveNewItem["content"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="imageFile" className="col-sm-3 col-form-label">Resim Dosyası</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="imageFile"
                id="imageFile"
                className="form-control"
                type="file"
                target={useActiveNewItem["imageFile"] ? useActiveNewItem["imageFile"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
            <div className="col-sm-2">
              {imageFromURL(useActiveNewItem["imageFile"]?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveNewItem["title"]) || ""}
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="voiceFile" className="col-sm-3 col-form-label">Ses Dosyası</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="voiceFile"
                id="voiceFile"
                className="form-control"
                type="file"
                target={useActiveNewItem["voiceFile"] ? useActiveNewItem["voiceFile"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
        <div className="form-group row mb-2">
          <Label for="type" className="col-sm-3 col-form-label">Tip</Label>
          <div className="col-sm-9">
            <Input id="type" className="form-control" type="text" value={postTypes.find((postType) => postType.value === useActiveViewItem["type"])?.name} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
          <div className="col-sm-9">
            <Input id="title" className="form-control" type="text" value={useActiveViewItem["title"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="content" className="col-sm-3 col-form-label">Başlık</Label>
          <div className="col-sm-9">
            <Input id="content" className="form-control" type="textarea" style={{height:"100px"}} value={useActiveViewItem["content"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="imageFile" className="col-sm-3 col-form-label">Resim Dosyası</Label>
          <div className="col-sm-9">
            {imageFromURL(useActiveViewItem["imageFile"]?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveViewItem["title"]) || ""}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="voiceFile" className="col-sm-3 col-form-label">Ses Dosyası</Label>
          <div className="col-sm-9">
            {useActiveViewItem["voiceFile"]?._id ? <audio controls><source src={audioURL(useActiveViewItem["voiceFile"]?._id)} type="audio/mpeg" /></audio> : ""}
          </div>
        </div>

        
      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="type" className="col-sm-3 col-form-label">Tip</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="type"
                id="type"
                className="form-control"
                type="select"
                required={true}
                value={useSelectedTypeEdit}
                onChange={handleEditItemChange}
              >
                {postTypes.map((postType, index) => {
                  return (
                    <option key={index} value={postType.value}>{postType.name}</option>
                  )
                })}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="title"
                id="title"
                className="form-control"
                type="text"
                required={true}
                value={useActiveEditItem["title"] ? useActiveEditItem["title"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="content" className="col-sm-3 col-form-label">Açıklama</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="content"
                id="content"
                className="form-control"
                type="textarea"
                style={{height: "100px"}}
                target={useActiveEditItem["content"] ? useActiveEditItem["content"] : ""}
                value={useActiveEditItem["content"]}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="imageFile" className="col-sm-3 col-form-label">Resim Dosyası</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="imageFile"
                id="imageFile"
                className="form-control"
                type="file"
                target={useActiveEditItem["imageFile"] ? useActiveEditItem["imageFile"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
            <div className="col-sm-2">
              {imageFromURL(useActiveEditItem["imageFile"]?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveEditItem["title"]) || ""}
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="voiceFile" className="col-sm-3 col-form-label">Ses Dosyası</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="voiceFile"
                id="voiceFile"
                className="form-control"
                type="file"
                target={useActiveEditItem["voiceFile"] ? useActiveEditItem["voiceFile"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>

          
        </form>
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.title} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.title}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stories);
