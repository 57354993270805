const slugifyModule = require('slugify');

export const token = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).token : null;

export const inputOp = (setFunction, e) => {
    return setFunction(e.target.value);
};

export const slugify = (text) => {
    return slugifyModule(text, {
        replacement: '-',
        remove: /[*+~.()'"!:@]/g,
        lower: true
    });
};

export const ifExists = (value) => {
    return value ? value : '';
}

export const is = (arg1, arg2) => {
    return arg1 === arg2;
}

export const isIn = (arg, arr) => {
    return arr.includes(arg);
};
